import { getSerializedData } from 'utils/get'
import { prop } from 'ramda'
import { fields } from '../components/Clinic/ClinicCreate'

export const clinicsCreateSerializer = params => {
  const serialized = getSerializedData(fields, params)
  const endTime = prop('endTime', params)
  const startTime = prop('startTime', params)

  return {
    ...serialized,
    workingTime: [{
      endTime,
      startTime
    }]
  }
}
