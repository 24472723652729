import React, { FunctionComponent } from 'react'
import { FieldWrapper } from 'components/StyledElems'
import { Field } from 'react-final-form'
import {
  CheckboxBordered,
  CheckboxField,
  InputField,
  InputRateField,
  RadioButtonBorderedField
} from 'components/Form/index'

import { TPositionItem } from 'types'
import styled from 'styled-components'

const FeeCeiling = styled.div`
  position: absolute;
  top: -32px;
  right: 0;
`

const FieldWrapperUI = styled(FieldWrapper)`
  position: relative;
`

export const billingFields = [
  'billingType',
  'fixedFeeAmount',
  'fixedFeePreAmount',
  'fixedFeeExpensesIncludedInFee',
  'hourlyHasFeeCeiling'
]
type Props = {
  hourlyHasFeeCeiling: boolean;
  positionList: TPositionItem[];
  isMultiple?: boolean;
}

const DISABLED = true
const BillingFields: FunctionComponent<Props> = props => {
  return (
    <>
      <FieldWrapperUI>
        <Field
          name="billingType"
          label="Fixed Fee"
          type="radio"
          value="fixed_fee"
          disabled={DISABLED}
          component={RadioButtonBorderedField}
        >

          <FieldWrapper>
            <Field
              name="fixedFeeAmount"
              label="Fee amount"
              disabled={DISABLED}
              component={InputField}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Field
              name="fixedFeePreAmount"
              label="Pre Payment Amount"
              disabled={DISABLED}
              component={InputField}
            />
          </FieldWrapper>
          <Field
            name="fixedFeeExpensesIncludedInFee"
            label={{ checkbox: 'Expenses included in Fee' }}
            disabled={DISABLED}
            type="checkbox"
            component={CheckboxBordered}
          />
        </Field>
      </FieldWrapperUI>
      <FieldWrapper>
        <Field
          name="billingType"
          label="Hourly Billing"
          type="radio"
          value="hourly_billing"
          disabled={DISABLED}
          component={RadioButtonBorderedField}
        >
          <FeeCeiling>
            <Field
              name="hourlyHasFeeCeiling"
              label="Fee Ceiling"
              disabled={DISABLED}
              component={CheckboxField}
            />
          </FeeCeiling>
          {props.hourlyHasFeeCeiling && (
            <FieldWrapper>
              <Field
                name="hourlyFeeCeiling"
                label="Fee Ceiling"
                disabled={DISABLED}
                component={InputField}
              />
            </FieldWrapper>
          )}
          {props.positionList.map(group => {
            return (
              <FieldWrapper key={group.id}>
                <Field
                  name={`rates[_${group.id}]`}
                  label={group.name}
                  addon="per/hr"
                  disabled={DISABLED}
                  component={InputRateField}
                />
              </FieldWrapper>
            )
          })}
        </Field>
      </FieldWrapper>
    </>
  )
}
BillingFields.defaultProps = {
  isMultiple: false
}
export default BillingFields
