import * as API from 'constants/api'
import React from 'react'
import styled from 'styled-components'
import { DisplayFlex, FieldWrapper } from 'components/StyledElems'
import { Box, InputLabel } from 'components/UI'
import { LinkButton } from 'components/UI/Buttons'
import { X } from 'react-feather'
import {
  CheckboxBordered, CheckboxField,
  Field,
  InputField, InputRateField,
  RadioButtonBorderedField,
  UniversalSearchField
} from '../index'

const NonSelectable = styled.div`
  user-select: none;
`
const BoxItem = styled(Box)`
  margin-bottom: 25px;
  max-height: calc(100vh - 330px);
  overflow: auto;
`

// const AddMoreButton = styled(PrimaryBorderedButtonSmall)`
const AddMoreButton = styled(LinkButton)`
  position: absolute;
  user-select: none;
  top: -5px;
  right: 0px;
`
const FeeCeiling = styled.div`
  position: absolute;
  top: -32px;
  right: 0;
`
const CoureselItem = styled.div<{isMore: boolean}>`
  width: ${props => props.isMore ? '48%' : '50%'};
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
  :last-child {
    margin-right: 0;
  }
`
const FieldWrapperUI = styled(FieldWrapper)`
  position: relative;
`
const FieldWrapperVisible = styled(FieldWrapper)`
  >div{
    height: auto;
    overflow: visible;
    max-height: 100%;
  }
`
const Label = styled.div`
  color: #8F9BB0;
  font-size: 20px;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 8px;
  margin-top: 8px;
`
const Close = styled.span`
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  :hover {
    background-color: #efefef;
  }
`

const AssignmentListField = props => {
  const { fields, isMultiple, isMore } = props

  const onAdd = () => {
    fields.push({})
  }

  const onRemove = index => fields.remove(index)

  return (
    <>
      {isMultiple && <AddMoreButton type="button" onClick={() => onAdd()} >Add more</AddMoreButton>}
      {fields.map((id, index) => {
        return (
          <CoureselItem key={index} isMore={isMore}>
            <BoxItem padding="25px">
              <NonSelectable>
                <DisplayFlex alignItems="center" justifyContent="space-between">
                  <Label>Assignment #{index + 1}</Label>
                  {isMore && <Close onClick={() => onRemove(index)}><X size="20px" /></Close>}
                </DisplayFlex>
                <FieldWrapper>
                  <Field
                    label="External Assignment Name"
                    name={`${id}.externalAssignmentName`}
                    component={InputField}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    label="Internal Assignment Name"
                    name={`${id}.internalAssignmentName`}
                    component={InputField}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    component={UniversalSearchField}
                    name={`${id}.teamLeader`}
                    label="Team Leader"
                    itemText={['fullName']}
                    api={API.STAFF_LIST}
                    params={{ is_lawyer: true }}
                  />
                </FieldWrapper>
                <div>
                  <InputLabel>Billing Type</InputLabel>
                  <div>
                    <FieldWrapperUI>
                      <Field
                        name={`${id}.billingType`}
                        label="Fixed Fee"
                        type="radio"
                        value="fixed_fee"
                        component={RadioButtonBorderedField}
                      >
                        <FieldWrapper>
                          <Field
                            name={`${id}.fixedFeeAmount`}
                            label="Fee amount"
                            component={InputField}
                          />
                        </FieldWrapper>
                        <FieldWrapper>
                          <Field
                            name={`${id}.fixedFeePreAmount`}
                            label="Pre Payment Amount"
                            component={InputField}
                          />
                        </FieldWrapper>
                        <Field
                          name={`${id}.fixedFeeExpensesIncludedInFee`}
                          label={{ checkbox: 'Expenses included in Fee' }}
                          type="checkbox"
                          component={CheckboxBordered}
                        />
                      </Field>
                    </FieldWrapperUI>
                    <FieldWrapperVisible>
                      <Field
                        name={`${id}.billingType`}
                        label="Hourly Billing"
                        type="radio"
                        value="hourly_billing"
                        component={RadioButtonBorderedField}
                      >
                        <FeeCeiling>
                          <Field
                            name={`${id}.hourlyHasFeeCeiling`}
                            label="Fee Ceiling"
                            component={CheckboxField}
                          />
                        </FeeCeiling>
                        {props.fields.value[index].hourlyHasFeeCeiling && (
                          <FieldWrapper>
                            <Field
                              name={`${id}.hourlyFeeCeiling`}
                              label="Fee Ceiling"
                              component={InputField}
                            />
                          </FieldWrapper>
                        )}
                        {props.positionList.map(group => {
                          return (
                            <FieldWrapper key={group.id}>
                              <Field
                                name={`${id}.rates[_${group.id}]`}
                                label={group.name}
                                addon="per/hr"
                                component={InputRateField}
                              />
                            </FieldWrapper>
                          )
                        })}
                      </Field>
                    </FieldWrapperVisible>
                  </div>
                </div>
              </NonSelectable>
            </BoxItem>
          </CoureselItem>
        )
      })}
    </>
  )
}

export default AssignmentListField
