import React, { FunctionComponent } from 'react'
import {
  Form
} from 'react-final-form'
import { DetailMenu } from 'components/Menu'
import { Box } from 'components/UI'
import { TAssignmentItem, TGetDataFromState, TOnSubmit } from 'types'
import { Merge } from 'types/utils'
import { DrawerSkeleton } from 'components/Skeletons'
import { path } from 'ramda'
import arrayMutators from 'final-form-arrays'
import AssignmentCreateForm from './AssignmentCreateForm'

type Props = {
  onSubmit: TOnSubmit;
  initialValues: Record<string, any>;
  id: string;
  itemLoading: boolean;
  item: TGetDataFromState<TAssignmentItem>;
}

type NewPropType = Merge<TGetDataFromState<TAssignmentItem | null>, Props>

const AssignmentUpdate: FunctionComponent<NewPropType> = props => {
  return (
    <div>
      <DetailMenu title={`Обновить ${path(['item', 'data', 'data', 'email'], props)}`} />
      <Box padding="25px">
        <DrawerSkeleton loading={props.itemLoading}>
          <Form
            onSubmit={props.onSubmit}
            keepDirtyOnReinitialize={true}
            initialValues={props.initialValues}
            mutators={{ ...arrayMutators }}
            render={formikProps => {
              return (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
                <AssignmentCreateForm
                  {...formikProps}
                />
              )
            }}
          />
        </DrawerSkeleton>
      </Box>
    </div>
  )
}

export default AssignmentUpdate
