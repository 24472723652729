import React, { FunctionComponent } from 'react'
import { Form } from 'react-final-form'
import { DetailMenu } from 'components/Menu/index'
import { Box } from 'components/UI'

import { TGetDataFromState, TOnSubmit, Merge, TGroupItem, TData, TPositionItem } from 'types'

import isEqual from 'react-fast-compare'
import arrayMutators from 'final-form-arrays'
import ClinicCreateForm from './ClinicCreateForm'

type Props = {
  onSubmit: TOnSubmit;
  positionData: TGetDataFromState<TData<TPositionItem>>;
}

type NewPropType = Merge<TGetDataFromState<TGroupItem | null>, Props>

export const fields = [
  'name',
  'phoneNumber',
  'userLimit',
]
const ContractCreate: FunctionComponent<NewPropType> = props => {
  return (
    <div>
      <DetailMenu title="Создание клиники" />
      <Box padding="25px">
        <Form
          onSubmit={props.onSubmit}
          mutators={{ ...arrayMutators }}
          render={formikProps => (
            <ClinicCreateForm
              {...formikProps}
            />
          )}
        />
      </Box>
    </div>
  )
}

export default React.memo(ContractCreate, isEqual)
