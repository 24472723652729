import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { RoundedImg } from 'components/StyledElems'

const ImgWrapper = styled.div`
  max-width: 70px;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
`
const Name = styled.div`
  margin-top: 10px;
  font-size: 13px;
  line-height: 135%;
  margin-bottom: 10px;
`

const ImgLeft = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`

const NameRight = styled.div`
  font-size: 13px;
  line-height: 135%;
  margin-left: 10px;
`

type Props = {
  name: string;
  src: string;
  highlight?: boolean;
}
const UserImg: FunctionComponent<Props> = props => {
  return (
    <ImgWrapper>
      <RoundedImg src={props.src} />
      <Name>{props.name}</Name>
    </ImgWrapper>
  )
}

export const ImgNameRight: FunctionComponent<Props> = props => {
  return (
    <ImgLeft>
      <RoundedImg src={props.src} highlight={props.highlight} />
      <NameRight>{props.name}</NameRight>
    </ImgLeft>
  )
}

export default UserImg
