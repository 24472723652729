import Layout from 'components/Layouts/Layout'
import * as ROUTES from '../../constants/routes'
import {
  AssignmentCreateContainer,
  AssignmentListContainer,
  AssignmentUpdateContainer,
  AssignmentDetailContainer
} from './containers/AssigmentContainers'

export default () => [
  {
    exact: true,
    path: ROUTES.ASSIGNMENT_LIST_PATH,
    layout: Layout,
    component: AssignmentListContainer
  },
  {
    exact: true,
    path: ROUTES.ASSIGNMENT_CREATE_PATH,
    component: AssignmentCreateContainer
  },
  {
    exact: true,
    path: ROUTES.ASSIGNMENT_UPDATE_PATH,
    component: AssignmentUpdateContainer
  },
  {
    exact: true,
    path: ROUTES.ASSIGNMENT_ITEM_PATH,
    layout: Layout,
    component: AssignmentDetailContainer
  },
]
