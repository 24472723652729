import React, { FunctionComponent } from 'react'
import { Field } from 'react-final-form'
import { InputLabel } from 'components/UI'
import { InputField, UniversalStaticSelectField } from 'components/Form'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  > div:first-child {
    width: 90px;
    margin-right: 15px;
  }
  > div:last-child {
    width: calc(100% - 105px);
  }
`
type Props = {
  label?: string;
}

const YEAR = 'year'
const MONTH = 'month'
const DAY = 'day'

const options = [
  { name: YEAR, id: YEAR },
  { name: MONTH, id: MONTH },
  { name: DAY, id: DAY }
]

const FrequencyPickerField: FunctionComponent<Props> = props => {
  const { label } = props
  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}

      <Wrapper>
        <Field
          component={InputField}
          name="frequencyInterval"
        />
        <Field
          component={UniversalStaticSelectField}
          name="frequencyType"
          list={options}
        />
      </Wrapper>

    </>
  )
}

export default FrequencyPickerField
