import React from 'react'
import { DropdownItem } from 'ui-cubic/dist/index.es'
import styled from 'styled-components'

const Item = styled(DropdownItem)`
 pointer-events: ${props => props.disabled && 'none'};
 color: ${props => props.disabled && props.theme.cube.lightGreyColor};

`
const DropDownItem = props => {
  return <Item toggleMenu={() => null} {...props} />
}

export default DropDownItem
