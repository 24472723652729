import React, { FunctionComponent } from 'react'
import {
  Approved,
  Rejected,
  Submitted,
  UnSubmitted
} from 'components/StyledElems'

const statusEl = {
  unsubmitted: UnSubmitted,
  submitted: Submitted,
  approved: Approved,
  rejected: Rejected
}

type Props = {
  children: string;
}
const ApprovalStatus: FunctionComponent<Props> = ({ children }) => {
  const Status = statusEl[children] || UnSubmitted
  const text = children === 'unsubmitted' ? 'not submitted' : children
  return <Status>{text}</Status>
}

export default ApprovalStatus
