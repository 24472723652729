import React from 'react'
import { useStore } from 'react-redux'
import {
  getOption,
  getOptions,
  defaultGetValue,
  defaultGetText
} from 'utils/searchField'
import * as API from '../../constants/api'
import SearchField from './Basic/SearchField'

const ClientSearchField = props => {
  const { api, params, itemText, ...rest } = props

  const store = useStore()

  return (
    <SearchField
      getText={defaultGetText(['name'])}
      getValue={defaultGetValue(['id'])}
      getOptions={search => getOptions(store, { api, params, search })}
      getOption={getOption(store, { api })}
      isClearable={true}
      itemText={itemText}
      label="Client"
      {...rest}
    />
  )
}

ClientSearchField.defaultProps = {
  itemText: ['name'],
  api: API.CLIENT_LIST
}

export default ClientSearchField
