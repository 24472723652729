import * as ROUTES from 'constants/routes'
import * as ACTIONS from 'constants/actionTypes'
import React from 'react'
import { path } from 'ramda'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ChevronDown, LogOut } from 'react-feather'
import { useUserInfo } from 'hooks'
import { Dropdown, DropdownItem } from 'components/UI'
import { RoundedImg } from 'components/StyledElems'
import expireDocumentCookie from 'utils/expireDocumentCookie'

const Container = styled('div')`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: 30px;
  & * {
    pointer-events: none; 
  }
  & > svg {
    color: #2c3a50;
    margin-left: 5px;
    stroke-width: 3;
    transition: ${props => props.theme.cube.transition};
    transform: rotate(${props => props.isOpen ? '180deg' : '0'});
  }
`

const UserName = styled('div')`
  margin-right: 12px;
  color: #2c3a50;
`

const UserInfo = () => {
  const userData = useUserInfo()
  const dispatch = useDispatch()
  const history = useHistory()

  const username = path(['user', 'username'], userData)

  const onLogOut = () => {
    expireDocumentCookie()
    dispatch({
      type: `${ACTIONS.LOGIN}_CLEAR`
    })
    history.replace(ROUTES.LOGIN_URL)
    window.location.reload()
  }

  return (
    <Dropdown
      trigger={triggerProps => {
        const { innerRef, isOpen, onClick } = triggerProps
        return (
          <Container
            ref={innerRef}
            onClick={onClick}
            isOpen={isOpen}
          >
            <UserName>{username}</UserName>
            <RoundedImg highlight={true} />
            <ChevronDown size={15} />
          </Container>
        )
      }}
    >
      <DropdownItem onClick={onLogOut}>
        <LogOut size={18} />Log out
      </DropdownItem>
    </Dropdown>
  )
}

UserInfo.propTypes = {}

export default UserInfo
