import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Field } from 'react-final-form'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import { path, map } from 'ramda'
import {
  Table,
  TableRow,
  TableCol,
  TableHeader,
  TableBody, TableColRight
} from 'components/Table'
import { TFeeItem } from 'types'
import { Tag } from 'components/StyledElems'
import { ApprovalStatus } from 'components/UI'
import dateFormat from 'utils/dateFormat'
import { getDuration } from 'utils/get'
import { getFeeAmount } from 'utils/getTyped'
import { InputField, CheckboxField } from 'components/Form'

type Props = {
  list: TFeeItem[];
  showType?: boolean;
  rejectMode?: boolean;
  typeModal?: string;
}
const HeaderRow = styled(TableRow)`
 label {
  opacity: 0.6;
  pointer-events: none;
 }
`
const InputFieldUI = styled(InputField)`
    height: 32px;
    border-radius: 4px;
    font-size: 13px;
    padding: 0 10px;
`

const FeeRejectListField: FunctionComponent<Props> = props => {
  const { list, showType, rejectMode, typeModal } = props

  const ids = map(path<number>(['id']), list)

  return (
    <FieldArray
      defaultValue={list}
      name={typeModal}
      render={(fieldProps: FieldArrayRenderProps<TFeeItem>) => {
        const { fields } = fieldProps
        const header = rejectMode
          ? (
            <HeaderRow align="center">
              <TableCol span={1} />
              <TableCol span={4}>Date</TableCol>
              <TableCol span={3} />
              <TableCol span={7}>Description</TableCol>
              <TableColRight span={2}>Time</TableColRight>
              <TableColRight span={4}>Cost</TableColRight>
              <TableCol span={7}>Comment</TableCol>
            </HeaderRow>
          )
          : (
            <HeaderRow align="center">
              <TableCol span={1} />
              <TableCol span={7}>Date</TableCol>
              <TableCol span={5} />
              <TableCol span={8}>Description</TableCol>
              <TableColRight span={3}>Time</TableColRight>
              <TableColRight span={4}>Cost</TableColRight>
            </HeaderRow>
          )
        return (
          <div>
            <Table gutter={20} list={ids}>
              <TableHeader>
                {header}
              </TableHeader>
              <TableBody>
                {fields.map((field, index) => {
                  const fee = fields.value[index]

                  const currency = path(['assignment', 'currency', 'name'], fee)
                  const feeAmount = getFeeAmount(fee.amount, currency)
                  const body = rejectMode
                    ? (
                      <TableRow align="center" key={index}>
                        <TableCol span={1}>
                          <Field
                            name={`${field}.${typeModal === 'expenses' ? 'expenses' : 'fees'}`}
                            component={CheckboxField}
                          />
                        </TableCol>
                        <TableCol span={4}>{dateFormat(fee.date)}</TableCol>
                        <TableCol span={3}>
                          {showType && <Tag>Fee</Tag>}
                          <ApprovalStatus>{fee.statusApprovement}</ApprovalStatus>
                        </TableCol>
                        <TableCol span={7}>{fee.description}</TableCol>
                        <TableColRight span={2}>{getDuration(fee.totalDuration)}</TableColRight>
                        <TableColRight span={4}>{feeAmount}</TableColRight>
                        <TableColRight span={7}>
                          <Field
                            name={`${field}.comment`}
                            component={InputFieldUI}
                          />
                        </TableColRight>
                      </TableRow>
                    )
                    : (
                      <TableRow align="center" key={index}>
                        <TableCol span={1}>
                          <Field
                            name={`${field}.${typeModal === 'expenses' ? 'expenses' : 'fees'}`}
                            component={CheckboxField}
                          />
                        </TableCol>
                        <TableCol span={7}>{dateFormat(fee.date)}</TableCol>
                        <TableCol span={5}>
                          {showType && <Tag>Fee</Tag>}
                          <ApprovalStatus>{fee.statusApprovement}</ApprovalStatus>
                        </TableCol>
                        <TableCol span={8}>{fee.description}</TableCol>
                        <TableColRight span={3}>{getDuration(fee.totalDuration)}</TableColRight>
                        <TableColRight span={4}>{feeAmount}</TableColRight>
                      </TableRow>
                    )
                  return body
                })}

              </TableBody>
            </Table>
          </div>
        )
      }}
    />

  )
}
FeeRejectListField.defaultProps = {
  showType: false,
  typeModal: 'fees'
}
export default FeeRejectListField
