import React from 'react'
import { TotalSkeleton } from 'components/Skeletons'
import styled from 'styled-components'

type Props = {
  loading: boolean;
  count?: number;
  children: any
}

const Grid = styled('div')<{count: number}>`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(${({ count }) => count}, 1fr);
  margin-bottom: 25px;
`
const TotalGrid = ({ children, loading, count }: Props) => {
  return (
    <>
      {loading
        ? <Grid count={count}><TotalSkeleton count={count} /></Grid>
        : <Grid count={count}>{children}</Grid>
      }
    </>
  )
}

export default TotalGrid
