import React from 'react'
import PropTypes from 'prop-types'
import { range } from 'ramda'
import styled from 'styled-components'
import { TotalCard } from 'components/Total'
import { Col } from '../UI'
import animation from './animation'
import { GREY } from './colors'

const Loader = styled('div')`
  animation: ${animation};
  background-color: ${GREY};
  border-radius: 8px;
  height: 20px;
  max-width: ${props => props.width};
  width: 100%;
`

const StyledCol = styled(Col)`
  flex-grow: unset;
`

const TotalSkeleton = props => {
  const { count } = props
  const list = range(0, count)
  return list.map(item => {
    return (
      <TotalCard key={item}>
        <StyledCol span={9}>
          <Loader width="85%" />
        </StyledCol>
        <br/>
        <StyledCol span={3}>
          <Loader width="40%" />
        </StyledCol>
      </TotalCard>
    )
  })
}

TotalSkeleton.propTypes = {
  count: PropTypes.number
}

TotalSkeleton.defaultProps = {
  count: 4
}

export default TotalSkeleton
