import React from 'react'
import { useStore } from 'react-redux'
import PropTypes from 'prop-types'
import {
  getOption,
  getOptions,
  defaultGetText,
  defaultGetValue, createOption
} from 'utils/searchField'
import MultiSearchField from '../Basic/MultiSearchField'
import MultiSearchCreateField from '../Basic/MultiSearchCreateField'

const UniversalMultiSearchField = props => {
  const { api, params, itemText, multi, ...rest } = props
  const store = useStore()

  if (multi) {
    return (
      <MultiSearchCreateField
        getText={(a) => {
          return defaultGetText(itemText)(a)
        }}
        getValue={defaultGetValue(['_id'])}
        getOptions={search => getOptions(store, { api, params, search })}
        getOption={getOption(store, { api, params })}
        createOption={value => createOption(store, { api, value })}
        isMulti={true}
        itemText={itemText}
        {...rest}
      />)
  }
  return (
    <MultiSearchField
      getText={(a) => {
        return defaultGetText(itemText)(a)
      }}
      getValue={defaultGetValue(['_id'])}
      getOptions={search => getOptions(store, { api, params, search })}
      getOption={getOption(store, { api, params })}
      isMulti={true}
      itemText={itemText}
      {...rest}
    />
  )
}

UniversalMultiSearchField.propTypes = {
  api: PropTypes.string.isRequired,
  params: PropTypes.object,
  itemText: PropTypes.array,
}

UniversalMultiSearchField.defaultProps = {
  itemText: ['name'],
  multi: false
}

export default UniversalMultiSearchField
