// import { pipe, values, map, fromPairs } from 'ramda'

/*
export const arrayObjToObj = pipe(
  map(values),
  fromPairs
)
*/

export const USER_STATUS_LIST = [
  {
    id: 'active',
    name: 'Активный',
  },
  {
    id: 'blocked',
    name: 'Заблокированный',
  }
]

export const STATUS_APPROVAL = {
  UN_SUBMITTED: 'unsubmitted',
  SUBMITTED: 'submitted',
  APPROVED: 'approved',
  REJECTED: 'rejected',
}

export const ASSIGNMENT_STATUS_LIST = [
  {
    id: 'draft',
    name: 'DRAFT',
  },
  {
    id: 'active',
    name: 'ACTIVE',
  },
  {
    id: 'completed',
    name: 'COMPLETED',
  },
  {
    id: 'issued',
    name: 'ISSUED',
  }
]

export const WEEKS = [
  { _id: 'Mo', name: 'Пн' },
  { _id: 'Tu', name: 'Вт' },
  { _id: 'We', name: 'Ср' },
  { _id: 'Th', name: 'Чт' },
  { _id: 'Fr', name: 'Пт' },
  { _id: 'Sa', name: 'Сб' },
  { _id: 'Su', name: 'Вс' }
]
