import * as stateNames from 'constants/stateNames'
import * as ROUTES from 'constants/routes'
import { useCreate } from 'hooks'
import React from 'react'
import Layout from 'components/Layouts/Layout'
import {
  assignmentCreateAction
} from '../../action/assignmentActions'
import AssignmentCreate from '../../components/Assignment/AssignmentCreate'
import { createSerializer } from '../../serializers/assignmentSerializer'

const AssignmentCreateContainer = () => {
  const data = useCreate({
    stateName: stateNames.ASSIGNMENT_CREATE,
    action: assignmentCreateAction,
    redirectUrl: ROUTES.ASSIGNMENT_LIST_PATH,
    serializer: createSerializer
  })

  return (
    <Layout>
      <AssignmentCreate
        {...data}
      />
    </Layout>
  )
}
export default AssignmentCreateContainer
