export const ROOT = ''
export const ROOT_PATH = `/${ROOT}`

const ID = ':id(\\d+)'

const LOGIN = 'login'
export const LOGIN_URL = `/${LOGIN}`

export const ORDER = 'order'
export const ORDER_LIST_PATH = `/${ORDER}`
export const ORDER_CREATE_PATH = `/${ORDER}/create`
export const ORDER_ITEM_PATH = `/${ORDER}/${ID}`
export const ORDER_ITEM_URL = `/${ORDER}/%d`
export const ORDER_UPDATE_PATH = `/${ORDER}/${ID}/update`
export const ORDER_UPDATE_URL = `/${ORDER}/%d/update`

export const CLIENT = 'client'
export const CLIENT_LIST_PATH = `/${CLIENT}`
export const CLIENT_CREATE_PATH = `/${CLIENT}/create`
export const CLIENT_ITEM_PATH = `/${CLIENT}/${ID}`
export const CLIENT_ITEM_URL = `/${CLIENT}/%d`
export const CLIENT_UPDATE_PATH = `/${CLIENT}/${ID}/update`
export const CLIENT_UPDATE_URL = `/${CLIENT}/%d/update`

export const STAFF = 'staff'
export const STAFF_LIST_PATH = `/${STAFF}`
export const STAFF_CREATE_PATH = `/${STAFF}/create`
export const STAFF_ITEM_PATH = `/${STAFF}/${ID}`
export const STAFF_ITEM_URL = `/${STAFF}/%d`
export const STAFF_UPDATE_PATH = `/${STAFF}/${ID}/update`
export const STAFF_UPDATE_URL = `/${STAFF}/%d/update`

export const TIME_SHEET = 'time-sheet'
export const TIME_SHEET_LIST_PATH = `/${TIME_SHEET}`
export const TIME_SHEET_CREATE_PATH = `/${TIME_SHEET}/create`
export const TIME_SHEET_ITEM_PATH = `/${TIME_SHEET}/${ID}`
export const TIME_SHEET_ITEM_URL = `/${TIME_SHEET}/%d`
export const TIME_SHEET_UPDATE_PATH = `/${TIME_SHEET}/${ID}/update`
export const TIME_SHEET_UPDATE_URL = `/${TIME_SHEET}/%d/update`

export const OUTSOURCE = 'outsource'
export const OUTSOURCE_LIST_PATH = `/${OUTSOURCE}`
export const OUTSOURCE_CREATE_PATH = `/${OUTSOURCE}/create`
export const OUTSOURCE_ITEM_PATH = `/${OUTSOURCE}/${ID}`
export const OUTSOURCE_ITEM_URL = `/${OUTSOURCE}/%d`
export const OUTSOURCE_UPDATE_PATH = `/${OUTSOURCE}/${ID}/update`
export const OUTSOURCE_UPDATE_URL = `/${OUTSOURCE}/%d/update`

export const FINANCE = 'finance'

export const PAYMENTS = 'payments'
export const PAYMENTS_LIST_PATH = `/${PAYMENTS}`
export const PAYMENTS_CREATE_PATH = `/${PAYMENTS}/create`
export const PAYMENTS_ITEM_PATH = `/${PAYMENTS}/${ID}`
export const PAYMENTS_ITEM_URL = `/${PAYMENTS}/%d`
export const PAYMENTS_UPDATE_PATH = `/${PAYMENTS}/${ID}/update`
export const PAYMENTS_UPDATE_URL = `/${PAYMENTS}/%d/update`

export const TRANSACTIONS = 'transactions'
export const TRANSACTIONS_LIST_PATH = `/${TRANSACTIONS}`
export const TRANSACTIONS_IN_CREATE_PATH = `/${TRANSACTIONS}/create`
export const TRANSACTIONS_OUT_CREATE_PATH = `/${TRANSACTIONS}/create-out`
export const TRANSACTIONS_ITEM_PATH = `/${TRANSACTIONS}/${ID}`
export const TRANSACTIONS_ITEM_URL = `/${TRANSACTIONS}/%d`
export const TRANSACTIONS_IN_UPDATE_PATH = `/${TRANSACTIONS}/${ID}/update`
export const TRANSACTIONS_OUT_UPDATE_PATH = `/${TRANSACTIONS}/${ID}/update-out`
export const TRANSACTIONS_IN_UPDATE_URL = `/${TRANSACTIONS}/%d/update`
export const TRANSACTIONS_OUT_UPDATE_URL = `/${TRANSACTIONS}/%d/update-out`

export const TRANSACTIONS_PENDING = 'transactions-pending'
export const TRANSACTIONS_PENDING_LIST_PATH = `/${TRANSACTIONS_PENDING}`
export const TRANSACTIONS_PENDING_CREATE_PATH = `/${TRANSACTIONS_PENDING}/create`
export const TRANSACTIONS_PENDING_ITEM_PATH = `/${TRANSACTIONS_PENDING}/${ID}`
export const TRANSACTIONS_PENDING_ITEM_URL = `/${TRANSACTIONS_PENDING}/%d`
export const TRANSACTIONS_PENDING_UPDATE_PATH = `/${TRANSACTIONS_PENDING}/${ID}/update`
export const TRANSACTIONS_PENDING_UPDATE_URL = `/${TRANSACTIONS_PENDING}/%d/update`

export const CASHBOX = 'cashbox'
export const CASHBOX_LIST_PATH = `/${CASHBOX}`
export const CASHBOX_CREATE_PATH = `/${CASHBOX}/create`
export const CASHBOX_ITEM_PATH = `/${CASHBOX}/${ID}`
export const CASHBOX_ITEM_URL = `/${CASHBOX}/%d`
export const CASHBOX_UPDATE_PATH = `/${CASHBOX}/${ID}/update`
export const CASHBOX_UPDATE_URL = `/${CASHBOX}/%d/update`

export const FINANCE_CASHBOX = 'finance/cashbox'
export const FINANCE_CASHBOX_LIST_PATH = `/${FINANCE_CASHBOX}`
export const FINANCE_CASHBOX_ITEM_PATH = `/${FINANCE_CASHBOX}/${ID}`
export const FINANCE_CASHBOX_ITEM_URL = `/${FINANCE_CASHBOX}/%d`

export const TAGS = 'tags'
export const TAGS_LIST_PATH = `/${TAGS}`
export const TAGS_CREATE_PATH = `/${TAGS}/create`
export const TAGS_ITEM_PATH = `/${TAGS}/${ID}`
export const TAGS_ITEM_URL = `/${TAGS}/%d`
export const TAGS_UPDATE_PATH = `/${TAGS}/${ID}/update`
export const TAGS_UPDATE_URL = `/${TAGS}/%d/update`

export const TEAMS = 'teams'
export const TEAMS_LIST_PATH = `/${TEAMS}`
export const TEAMS_CREATE_PATH = `/${TEAMS}/create`
export const TEAMS_ITEM_PATH = `/${TEAMS}/${ID}`
export const TEAMS_ITEM_URL = `/${TEAMS}/%d`
export const TEAMS_UPDATE_PATH = `/${TEAMS}/${ID}/update`
export const TEAMS_UPDATE_URL = `/${TEAMS}/%d/update`

export const CURRENCY = 'currency'
export const CURRENCY_LIST_PATH = `/${CURRENCY}`
export const CURRENCY_CREATE_PATH = `/${CURRENCY}/create`
export const CURRENCY_ITEM_PATH = `/${CURRENCY}/${ID}`
export const CURRENCY_ITEM_URL = `/${CURRENCY}/%d`
export const CURRENCY_UPDATE_PATH = `/${CURRENCY}/${ID}/update`
export const CURRENCY_UPDATE_URL = `/${CURRENCY}/%d/update`

export const BRANCH = 'branch'
export const BRANCH_LIST_PATH = `/${BRANCH}`
export const BRANCH_CREATE_PATH = `/${BRANCH}/create`
export const BRANCH_ITEM_PATH = `/${BRANCH}/${ID}`
export const BRANCH_ITEM_URL = `/${BRANCH}/%d`
export const BRANCH_UPDATE_PATH = `/${BRANCH}/${ID}/update`
export const BRANCH_UPDATE_URL = `/${BRANCH}/%d/update`

export const GROUP = 'group'
export const GROUP_LIST_PATH = `/${GROUP}`
export const GROUP_CREATE_PATH = `/${GROUP}/create`
export const GROUP_ITEM_PATH = `/${GROUP}/${ID}`
export const GROUP_ITEM_URL = `/${GROUP}/%d`
export const GROUP_UPDATE_PATH = `/${GROUP}/${ID}/update`
export const GROUP_UPDATE_URL = `/${GROUP}/%d/update`

export const CONTRACT = 'contract'
export const CONTRACT_LIST_PATH = `/${CONTRACT}`
export const CONTRACT_CREATE_PATH = `/${CONTRACT}/create`
export const CONTRACT_ITEM_PATH = `/${CONTRACT}/${ID}`
export const CONTRACT_ITEM_URL = `/${CONTRACT}/%d`
export const CONTRACT_UPDATE_PATH = `/${CONTRACT}/${ID}/update`
export const CONTRACT_UPDATE_URL = `/${CONTRACT}/%d/update`

export const ASSIGNMENT = 'assignment'
export const ASSIGNMENT_LIST_PATH = `/${ASSIGNMENT}`
export const ASSIGNMENT_CREATE_PATH = `/${ASSIGNMENT}/create`
export const ASSIGNMENT_ITEM_PATH = `/${ASSIGNMENT}/:id/detail`
export const ASSIGNMENT_ITEM_URL = `/${ASSIGNMENT}/%s/detail`
export const ASSIGNMENT_UPDATE_PATH = `/${ASSIGNMENT}/:id/update`
export const ASSIGNMENT_UPDATE_URL = `/${ASSIGNMENT}/%s/update`

export const CLINIC = 'clinic'
export const CLINIC_LIST_PATH = `/${CLINIC}`
export const CLINIC_CREATE_PATH = `/${CLINIC}/create`
export const CLINIC_ITEM_PATH = `/${CLINIC}/:id/detail`
export const CLINIC_ITEM_URL = `/${CLINIC}/%s/detail`
export const CLINIC_UPDATE_PATH = `/${CLINIC}/:id/update`
export const CLINIC_UPDATE_URL = `/${CLINIC}/%s/update`

export const APPROVAL = 'approval'
export const APPROVAL_LIST_PATH = `/${APPROVAL}`
export const APPROVAL_CREATE_PATH = `/${APPROVAL}/create`
export const APPROVAL_ITEM_PATH = `/${APPROVAL}/${ID}`
export const APPROVAL_ITEM_URL = `/${APPROVAL}/%d`
export const APPROVAL_UPDATE_PATH = `/${APPROVAL}/${ID}/update`
export const APPROVAL_UPDATE_URL = `/${APPROVAL}/%d/update`

export const POSITION = 'position'
export const POSITION_LIST_PATH = `/${POSITION}`
export const POSITION_CREATE_PATH = `/${POSITION}/create`
export const POSITION_ITEM_PATH = `/${POSITION}/${ID}`
export const POSITION_ITEM_URL = `/${POSITION}/%d`
export const POSITION_UPDATE_PATH = `/${POSITION}/${ID}/update`
export const POSITION_UPDATE_URL = `/${POSITION}/%d/update`

export const ROLE = 'role'
export const ROLE_LIST_PATH = `/${ROLE}`
export const ROLE_CREATE_PATH = `/${ROLE}/create`
export const ROLE_ITEM_PATH = `/${ROLE}/${ID}`
export const ROLE_ITEM_URL = `/${ROLE}/%d`
export const ROLE_UPDATE_PATH = `/${ROLE}/${ID}/update`
export const ROLE_UPDATE_URL = `/${ROLE}/%d/update`

export const BANK_ACCOUNT = 'bank'
export const BANK_ACCOUNT_LIST_PATH = `/${BANK_ACCOUNT}`
export const BANK_ACCOUNT_CREATE_PATH = `/${BANK_ACCOUNT}/create`
export const BANK_ACCOUNT_ITEM_PATH = `/${BANK_ACCOUNT}/${ID}`
export const BANK_ACCOUNT_ITEM_URL = `/${BANK_ACCOUNT}/%d`
export const BANK_ACCOUNT_UPDATE_PATH = `/${BANK_ACCOUNT}/${ID}/update`
export const BANK_ACCOUNT_UPDATE_URL = `/${BANK_ACCOUNT}/%d/update`

export const UNINVOICED = 'uninvoiced'
export const UNINVOICED_LIST_PATH = `/${UNINVOICED}`
export const UNINVOICED_CREATE_PATH = `/${UNINVOICED}/${ID}/create`
export const UNINVOICED_CREATE_URL = `/${UNINVOICED}/%d/create`
export const UNINVOICED_ITEM_PATH = `/${UNINVOICED}/${ID}`
export const UNINVOICED_ITEM_URL = `/${UNINVOICED}/%d`
export const UNINVOICED_UPDATE_PATH = `/${UNINVOICED}/${ID}/update`
export const UNINVOICED_UPDATE_URL = `/${UNINVOICED}/%d/update`

export const INVOICE = 'invoice'
export const INVOICE_LIST_PATH = `/${INVOICE}`
export const INVOICE_CREATE_PATH = `/${INVOICE}/${ID}/create`
export const INVOICE_PREPAYMENT_CREATE_PATH = `/${INVOICE}/${ID}/prepayment`
export const INVOICE_PREPAYMENT_CREATE_URL = `/${INVOICE}/%d/prepayment`
export const INVOICE_CREATE_URL = `/${INVOICE}/%d/create`
export const INVOICE_ITEM_PATH = `/${INVOICE}/${ID}`
export const INVOICE_ITEM_URL = `/${INVOICE}/%d`
export const INVOICE_UPDATE_PATH = `/${INVOICE}/${ID}/update`
export const INVOICE_UPDATE_URL = `/${INVOICE}/%d/update`

export const RECURRING_INVOICE = 'recurring-invoice'
export const RECURRING_INVOICE_LIST_PATH = `/${RECURRING_INVOICE}`
export const RECURRING_INVOICE_CREATE_PATH = `/${RECURRING_INVOICE}/${ID}/create`
export const RECURRING_INVOICE_CREATE_URL = `/${RECURRING_INVOICE}/%d/create`
export const RECURRING_INVOICE_ITEM_PATH = `/${RECURRING_INVOICE}/${ID}`
export const RECURRING_INVOICE_ITEM_URL = `/${RECURRING_INVOICE}/%d`
export const RECURRING_INVOICE_UPDATE_PATH = `/${RECURRING_INVOICE}/${ID}/update`
export const RECURRING_INVOICE_UPDATE_URL = `/${RECURRING_INVOICE}/%d/update`

const EXPENSE = 'expense'
export const EXPENSE_LIST_PATH = `/${EXPENSE}`
export const EXPENSE_CREATE_PATH = `/${EXPENSE}/${ID}/create`
export const EXPENSE_CREATE_URL = `/${EXPENSE}/%d/create`
export const EXPENSE_ITEM_PATH = `/${EXPENSE}/${ID}`

const CLIENT_BALANCE = 'client-balance'
export const CLIENT_BALANCE_LIST_PATH = `/${CLIENT_BALANCE}`
export const CLIENT_BALANCE_CREATE_PATH = `/${CLIENT_BALANCE}/${ID}/create`
export const CLIENT_BALANCE_CREATE_URL = `/${CLIENT_BALANCE}/%d/create`
export const CLIENT_BALANCE_ITEM_URL = `/${CLIENT_BALANCE}/%d/`
export const CLIENT_BALANCE_ITEM_PATH = `/${CLIENT_BALANCE}/${ID}`
export const STATS = 'https://shive.ritm.uz/#/'
export const STATS_URL = `${STATS}`
