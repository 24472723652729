import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import {
  TAssignmentItem,
} from 'types'
import {
  Table,
  TableBody,
  TableCol,
  TableHeader,
  TableRow,
  TableContainer
} from 'components/Table'
import { Box } from 'components/UI'
import { SubTitle } from 'components/DetailComponents'
import { DisplayFlex } from 'components/StyledElems'
import { CARD_WIDTH } from './AssignmentDetailsInfo'

const BoxUI = styled(Box)`
  width: calc(100% - ${CARD_WIDTH} - 30px);
  margin-right: 30px;
`

const Header = styled(DisplayFlex)`
  padding-bottom: 22px;
  margin-bottom: 20px;
  border-bottom: ${props => props.theme.border};
`

type Props = {
  details: TAssignmentItem;
};

export const fields = ['fees', 'expenses']

const AssignmentFeeExpenseList: FunctionComponent<Props> = () => {
  return (
    <BoxUI padding="22px 25px">
      <Header alignItems="center" justifyContent="space-between">
        <SubTitle>Время</SubTitle>
      </Header>
      <TableContainer>
        <Table>
          <TableHeader>
            <TableRow>
              <TableCol span={10}>Начало</TableCol>
              <TableCol span={3}>Окончание</TableCol>
            </TableRow>
          </TableHeader>
          <TableBody>
            {[1].map((item) => {
              return (
                <TableRow align="center" key={item}>
                  <TableCol span={10}>
                    3333
                  </TableCol>
                  <TableCol span={3}>{333}</TableCol>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </BoxUI>
  )
}

export default AssignmentFeeExpenseList
