import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { XCircle } from 'react-feather'
import { replaceParamsRoute } from 'utils/route'
import { getParamFromHistory } from 'utils/get'
import Search from '../../icons/Search'
import { IconInput } from '../UI'

const Input = styled(IconInput)`
  align-self: center;
  background-color: #fbfbfc;
  border: 1px solid #e4e5eb;
  border-radius: 6px;
  padding-left: 44px;
  height: 36px;
  ::placeholder {
    color: #b2b7bf;
    font-size: 14px;
  }
`
const Wrapper = styled.div`
  position: relative;
`

const Clear = styled.div`
  cursor:pointer;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px;
  transition: all 200ms;
  display: flex;
  border-radius: 50%;
  :hover {
    background-color: #e3e7ea;
    
  }
`

const SearchField = props => {
  const { key } = props
  const history = useHistory()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [val, setVal] = React.useState<string>(getParamFromHistory(key, history))
  const decodedValue = val ? decodeURIComponent(val) : ''

  const onEnter = (ev, search) => {
    const historyValue = getParamFromHistory(key, history)
    if (historyValue !== search) {
      replaceParamsRoute({ [key]: search, page: null }, history)
    }
  }

  const onChange = ev => setVal(ev.target.value)

  const onClear = ev => {
    replaceParamsRoute({ [key]: null, page: null }, history)
    setVal('')
    return ev
  }
  return (
    <Wrapper>
      <Input
        {...props}
        data-cy="table-search"
        value={decodedValue}
        onChange={onChange}
        onEnter={onEnter}
        icon={Search}
        placeholder="Поиск..."
      />
      {val && (
        <Clear onClick={onClear}>
          <XCircle size={20} color="rgb(143, 155, 176)" />
        </Clear>
      )}
    </Wrapper>
  )
}

SearchField.propTypes = {
  key: PropTypes.string
}

SearchField.defaultProps = {
  key: 'search'
}

export default SearchField
