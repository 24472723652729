export const SIGN_IN = 'SIGN_IN'
export const DELIVERY = 'DELIVERY'

export const PROFILE = 'PROFILE'

export const ADDRESS_CREATE = 'ADDRESS_CREATE'
export const ADDRESS_LIST = 'ADDRESS_LIST'
export const ADDRESS_DELETE = 'ADDRESS_DELETE'

export const FAVOURITE_CREATE = 'FAVOURITE_CREATE'
export const FAVOURITE_LIST = 'FAVOURITE_LIST'
export const FAVOURITE_DELETE = 'FAVOURITE_DELETE'

export const CLINICS_CREATE = 'CLINICS_CREATE'
export const CLINICS_LIST = 'CLINICS_LIST'
export const CLINICS_ITEM = 'CLINICS_ITEM'
export const CLINICS_UPDATE = 'CLINICS_UPDATE'
export const CLINICS_DELETE = 'CLINICS_DELETE'

export const FILTER_LIST = 'FILTER_LIST'

export const COMMENT_CREATE = 'COMMENT_CREATE'
export const COMMENT_LIST = 'COMMENT_LIST'
export const COMMENT_ITEM = 'COMMENT_ITEM'
export const LOGIN = 'LOGIN'
export const USER_INFO = 'USER_INFO'

export const ORDER_CREATE = 'ORDER_CREATE'
export const ORDER_LIST = 'ORDER_LIST'
export const ORDER_ITEM = 'ORDER_ITEM'
export const ORDER_DELETE = 'ORDER_DELETE'
export const ORDER_UPDATE = 'ORDER_UPDATE'

export const STAFF_CREATE = 'STAFF_CREATE'
export const STAFF_LIST = 'STAFF_LIST'
export const STAFF_ITEM = 'STAFF_ITEM'
export const STAFF_DELETE = 'STAFF_DELETE'
export const STAFF_UPDATE = 'STAFF_UPDATE'

export const TAGS_CREATE = 'TAGS_CREATE'
export const TAGS_LIST = 'TAGS_LIST'
export const TAGS_ITEM = 'TAGS_ITEM'
export const TAGS_DELETE = 'TAGS_DELETE'
export const TAGS_UPDATE = 'TAGS_UPDATE'

export const TEAMS_CREATE = 'TEAMS_CREATE'
export const TEAMS_LIST = 'TEAMS_LIST'
export const TEAMS_ITEM = 'TEAMS_ITEM'
export const TEAMS_DELETE = 'TEAMS_DELETE'
export const TEAMS_UPDATE = 'TEAMS_UPDATE'

export const PAYMENTS_CREATE = 'PAYMENTS_CREATE'
export const PAYMENTS_LIST = 'PAYMENTS_LIST'
export const PAYMENTS_ITEM = 'PAYMENTS_ITEM'
export const PAYMENTS_DELETE = 'PAYMENTS_DELETE'
export const PAYMENTS_UPDATE = 'PAYMENTS_UPDATE'

export const CASHBOX_CREATE = 'CASHBOX_CREATE'
export const CASHBOX_LIST = 'CASHBOX_LIST'
export const CASHBOX_ITEM = 'CASHBOX_ITEM'
export const CASHBOX_DELETE = 'CASHBOX_DELETE'
export const CASHBOX_UPDATE = 'CASHBOX_UPDATE'

export const FINANCE_CASHBOX_LIST = 'FINANCE_CASHBOX_LIST'
export const FINANCE_CASHBOX_TOTAL = 'FINANCE_CASHBOX_TOTAL'

export const FINANCE_CASHBOX_DETAIL_LIST = 'FINANCE_CASHBOX_DETAIL_LIST'
export const FINANCE_CASHBOX_DETAIL_TOTAL = 'FINANCE_CASHBOX_DETAIL_TOTAL'

export const TRANSACTIONS_CREATE = 'TRANSACTIONS_CREATE'
export const TRANSACTIONS_LIST = 'TRANSACTIONS_LIST'
export const TRANSACTIONS_ITEM = 'TRANSACTIONS_ITEM'
export const TRANSACTIONS_DELETE = 'TRANSACTIONS_DELETE'
export const TRANSACTIONS_UPDATE = 'TRANSACTIONS_UPDATE'

export const TRANSACTIONS_PENDING_CREATE = 'TRANSACTIONS_PENDING_CREATE'
export const TRANSACTIONS_PENDING_LIST = 'TRANSACTIONS_PENDING_LIST'
export const TRANSACTIONS_PENDING_ITEM = 'TRANSACTIONS_PENDING_ITEM'
export const TRANSACTIONS_PENDING_DELETE = 'TRANSACTIONS_PENDING_DELETE'
export const TRANSACTIONS_PENDING_UPDATE = 'TRANSACTIONS_PENDING_UPDATE'

export const CURRENCY_CREATE = 'CURRENCY_CREATE'
export const CURRENCY_LIST = 'CURRENCY_LIST'
export const CURRENCY_ITEM = 'CURRENCY_ITEM'
export const CURRENCY_DELETE = 'CURRENCY_DELETE'
export const CURRENCY_UPDATE = 'CURRENCY_UPDATE'

export const BRANCH_CREATE = 'BRANCH_CREATE'
export const BRANCH_LIST = 'BRANCH_LIST'
export const BRANCH_ITEM = 'BRANCH_ITEM'
export const BRANCH_DELETE = 'BRANCH_DELETE'
export const BRANCH_UPDATE = 'BRANCH_UPDATE'

export const GROUP_CREATE = 'GROUP_CREATE'
export const GROUP_LIST = 'GROUP_LIST'
export const GROUP_ITEM = 'GROUP_ITEM'
export const GROUP_DELETE = 'GROUP_DELETE'
export const GROUP_UPDATE = 'GROUP_UPDATE'

export const CONTRACT_CREATE = 'CONTRACT_CREATE'
export const CONTRACT_LIST = 'CONTRACT_LIST'
export const CONTRACT_ITEM = 'CONTRACT_ITEM'
export const CONTRACT_DELETE = 'CONTRACT_DELETE'
export const CONTRACT_UPDATE = 'CONTRACT_UPDATE'

export const CONTRACT_ID = 'CONTRACT_ID'

export const CONTRACT_CONFIG_CREATE = 'CONTRACT_CONFIG_CREATE'
export const CONTRACT_CONFIG_LIST = 'CONTRACT_CONFIG_LIST'
export const CONTRACT_CONFIG_ITEM = 'CONTRACT_CONFIG_ITEM'
export const CONTRACT_CONFIG_DELETE = 'CONTRACT_CONFIG_DELETE'
export const CONTRACT_CONFIG_UPDATE = 'CONTRACT_CONFIG_UPDATE'
export const CONTRACT_CONFIG_BILLING_ITEM = 'CONTRACT_CONFIG_BILLING_ITEM'

export const CONTRACT_CONFIG_ID = 'CONTRACT_CONFIG_ID'

export const ASSIGNMENT_CREATE = 'ASSIGNMENT_CREATE'
export const ASSIGNMENT_LIST = 'ASSIGNMENT_LIST'
export const ASSIGNMENT_PURE_LIST = 'ASSIGNMENT_PURE_LIST'
export const ASSIGNMENT_ITEM = 'ASSIGNMENT_ITEM'
export const ASSIGNMENT_DELETE = 'ASSIGNMENT_DELETE'
export const ASSIGNMENT_UPDATE = 'ASSIGNMENT_UPDATE'
export const ASSIGNMENT_COMPLETE = 'ASSIGNMENT_COMPLETE'

export const ASSIGNMENT_SUBMITTED_LIST = 'ASSIGNMENT_SUBMITTED_LIST'
export const ASSIGNMENT_APPROVED_LIST = 'ASSIGNMENT_APPROVED_LIST'
export const ASSIGNMENT_SUBMITTED_ITEM = 'ASSIGNMENT_SUBMITTED_ITEM'

export const PERMISSION_CREATE = 'PERMISSION_CREATE'
export const PERMISSION_LIST = 'PERMISSION_LIST'
export const PERMISSION_ITEM = 'PERMISSION_ITEM'
export const PERMISSION_DELETE = 'PERMISSION_DELETE'
export const PERMISSION_UPDATE = 'PERMISSION_UPDATE'

export const CLIENT_CREATE = 'CLIENT_CREATE'
export const CLIENT_LIST = 'CLIENT_LIST'
export const CLIENT_ITEM = 'CLIENT_ITEM'
export const CLIENT_DELETE = 'CLIENT_DELETE'
export const CLIENT_UPDATE = 'CLIENT_UPDATE'

export const CLIENT_CONTACT_CREATE = 'CLIENT_CONTACT_CREATE'
export const CLIENT_CONTACT_LIST = 'CLIENT_CONTACT_LIST'
export const CLIENT_CONTACT_ITEM = 'CLIENT_CONTACT_ITEM'
export const CLIENT_CONTACT_DELETE = 'CLIENT_CONTACT_DELETE'
export const CLIENT_CONTACT_UPDATE = 'CLIENT_CONTACT_UPDATE'

export const OUTSOURCE_CREATE = 'OUTSOURCE_CREATE'
export const OUTSOURCE_LIST = 'OUTSOURCE_LIST'
export const OUTSOURCE_ITEM = 'OUTSOURCE_ITEM'
export const OUTSOURCE_DELETE = 'OUTSOURCE_DELETE'
export const OUTSOURCE_UPDATE = 'OUTSOURCE_UPDATE'

export const OUTSOURCE_CONTACT_CREATE = 'OUTSOURCE_CONTACT_CREATE'
export const OUTSOURCE_CONTACT_LIST = 'OUTSOURCE_CONTACT_LIST'
export const OUTSOURCE_CONTACT_ITEM = 'OUTSOURCE_CONTACT_ITEM'
export const OUTSOURCE_CONTACT_DELETE = 'OUTSOURCE_CONTACT_DELETE'
export const OUTSOURCE_CONTACT_UPDATE = 'OUTSOURCE_CONTACT_UPDATE'

export const POSITION_CREATE = 'POSITION_CREATE'
export const POSITION_LIST = 'POSITION_LIST'
export const POSITION_ITEM = 'POSITION_ITEM'
export const POSITION_DELETE = 'POSITION_DELETE'
export const POSITION_UPDATE = 'POSITION_UPDATE'

export const ROLE_CREATE = 'ROLE_CREATE'
export const ROLE_LIST = 'ROLE_LIST'
export const ROLE_ITEM = 'ROLE_ITEM'
export const ROLE_DELETE = 'ROLE_DELETE'
export const ROLE_UPDATE = 'ROLE_UPDATE'

export const BANK_ACCOUNT_CREATE = 'BANK_ACCOUNT_CREATE'
export const BANK_ACCOUNT_LIST = 'BANK_ACCOUNT_LIST'
export const BANK_ACCOUNT_ITEM = 'BANK_ACCOUNT_ITEM'
export const BANK_ACCOUNT_DELETE = 'BANK_ACCOUNT_DELETE'
export const BANK_ACCOUNT_UPDATE = 'BANK_ACCOUNT_UPDATE'

export const FEE_CREATE = 'FEE_CREATE'
export const FEE_LIST = 'FEE_LIST'
export const FEE_TIMER_LIST = 'FEE_TIMER_LIST'
export const FEE_TIMER_FINISHED_LIST = 'FEE_TIMER_FINISHED_LIST'
export const FEE_TIMER_ITEM = 'FEE_TIMER_ITEM'
export const FEE_ALL_LIST = 'FEE_ALL_LIST'
export const FEE_ITEM = 'FEE_ITEM'
export const FEE_DELETE = 'FEE_DELETE'
export const FEE_ITEM_DELETE = 'FEE_ITEM_DELETE'
export const FEE_UPDATE = 'FEE_UPDATE'
export const FEE_STATUS_CHANGE = 'FEE_STATUS_CHANGE'
export const FEE_STATUS_REJECT = 'FEE_STATUS_REJECT'
export const FEE_TIMER_CHANGE_STATUS = 'FEE_TIMER_CHANGE_STATUS'
export const FEE_TIMER_CREATE = 'FEE_TIMER_CREATE'
export const FEE_TIMER_PAUSE = 'FEE_TIMER_PAUSE'
export const FEE_TIMER_PLAY = 'FEE_TIMER_PLAY'
export const FEE_TIMER_FINISH = 'FEE_TIMER_FINISH'
export const FEE_TIMER_ACTIVE = 'FEE_TIMER_ACTIVE'
export const FEE_PERIOD_LIST = 'FEE_PERIOD_LIST'

export const EXPENSE_CREATE = 'EXPENSE_CREATE'
export const EXPENSE_LIST = 'EXPENSE_LIST'
export const EXPENSE_ALL_LIST = 'EXPENSE_ALL_LIST'
export const EXPENSE_ITEM = 'EXPENSE_ITEM'
export const EXPENSE_DELETE = 'EXPENSE_DELETE'
export const EXPENSE_UPDATE = 'EXPENSE_UPDATE'
export const EXPENSE_STATUS_CHANGE = 'EXPENSE_STATUS_CHANGE'
export const EXPENSE_STATUS_REJECT = 'EXPENSE_STATUS_REJECT'

export const UNINVOICED_CREATE = 'UNINVOICED_CREATE'
export const UNINVOICED_LIST = 'UNINVOICED_LIST'
export const UNINVOICED_ITEM = 'UNINVOICED_ITEM'
export const UNINVOICED_DELETE = 'UNINVOICED_DELETE'
export const UNINVOICED_UPDATE = 'UNINVOICED_UPDATE'

export const INVOICE_CREATE = 'INVOICE_CREATE'
export const INVOICE_LIST = 'INVOICE_LIST'
export const INVOICE_ITEM = 'INVOICE_ITEM'
export const INVOICE_DELETE = 'INVOICE_DELETE'
export const INVOICE_UPDATE = 'INVOICE_UPDATE'

export const RECURRING_INVOICE_CREATE = 'RECURRING_INVOICE_CREATE'
export const RECURRING_INVOICE_LIST = 'RECURRING_INVOICE_LIST'
export const RECURRING_INVOICE_ITEM = 'RECURRING_INVOICE_ITEM'
export const RECURRING_INVOICE_DELETE = 'RECURRING_INVOICE_DELETE'
export const RECURRING_INVOICE_UPDATE = 'RECURRING_INVOICE_UPDATE'

export const INVOICE_TOTAL_PAYMENT = 'INVOICE_TOTAL_PAYMENT'
export const INVOICE_MONTH_PAYMENT = 'INVOICE_MONTH_PAYMENT'
export const INVOICE_PAYMENT = 'INVOICE_PAYMENT'
export const INVOICE_PAYMENT_LIST = 'INVOICE_PAYMENT_LIST'
export const INVOICE_BALANCE_PAYMENT = 'INVOICE_BALANCE_PAYMENT'
export const INVOICE_SEND = 'INVOICE_SEND'
export const INVOICE_DELIVERED = 'INVOICE_DELIVERED'
