import React, { useState, useEffect, useRef } from 'react'
import { filter, isEmpty, not, pipe, propEq, path, length } from 'ramda'
import { shallowEqual, useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import { Bell } from 'react-feather'
import NotificationsList from './NotificationsList'

const rotateDegree = '8deg'
const animationDuration = 500
const bellAnimate = keyframes`
  0% {
    transform: rotate(${rotateDegree});
  }
  25% {
    transform: rotate(-${rotateDegree});
  }
  50% {
    transform: rotate(${rotateDegree});
  }
  75% {
    transform: rotate(-${rotateDegree});
  }
  100% {
    transform: rotate(0);
  }
`

const Container = styled('div')`
  
`

const IconContainer = styled('div')`
  background-color: ${props => props.open ? '#f1f3f5' : 'transparent'};
  border-radius: 8px;
  cursor: pointer;
  padding: 8px;
  position: relative;
  transition: ${props => props.theme.cube.transition};
  height: 38px;
  width: 38px;
  & * {
    pointer-events: none;
  }
  & > svg {
    animation-duration: ${animationDuration}ms;
    color: #2c3a50;
    display: block;
    transform-origin: top center;
  }
  &.animate > svg {
    animation-name: ${bellAnimate};
  }
`

const Badge = styled('div')`
  background-color: ${props => props.theme.cube.colorOrange};
  border-radius: 50%;
  position: absolute;
  top: 4px;
  right: 10px;
  height: 8px;
  width: 8px;
`

const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const Notifications = () => {
  const notifyWrapRef = useRef()
  const notifyBellRef = useRef()
  const notifyData = useSelector(path(['notify', 'data']), shallowEqual) || []
  const notifyDataCount = length(notifyData)
  const [open, setOpen] = useState(false)

  const handleClick = event => {
    const isClickedOutside = notifyWrapRef.current && !notifyWrapRef.current.contains(event.target)
    if (open && isClickedOutside) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })

  const prevNotifyDataCount = usePrevious(notifyDataCount)
  useEffect(() => {
    if (prevNotifyDataCount < notifyDataCount) {
      const bell = notifyBellRef.current
      const toggleTimeout = setTimeout(() => {
        if (bell) bell.classList.remove('animate')
      }, animationDuration)
      if (bell) bell.classList.add('animate')
      return () => {
        clearTimeout(toggleTimeout)
      }
    }
  }, [notifyDataCount, prevNotifyDataCount])

  const toggleOpen = () => {
    setOpen(not(open))
  }

  const hasNotifications = pipe(
    filter(propEq('isRead', false)),
    isEmpty,
    not
  )(notifyData)

  return (
    <Container>
      <IconContainer open={open} onClick={toggleOpen} ref={notifyBellRef}>
        {hasNotifications && (
          <Badge />
        )}
        <Bell size={22} />
      </IconContainer>

      <div ref={notifyWrapRef}>
        {open && (
          <NotificationsList
            data={notifyData}
            hasNotifications={hasNotifications}
            onReadNotify={() => null}
            onDelete={() => null}
            onClear={() => null}
          />
        )}
      </div>
    </Container>
  )
}

export default Notifications
