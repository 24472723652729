import * as stateNames from 'constants/stateNames'
import * as ROUTES from 'constants/routes'
import React from 'react'
import { sprintf } from 'sprintf-js'
import {
  useFetchList,
  useFilterActions,
  useDelete
} from 'hooks'

import { DEFAULT_PICK_PARAMS } from 'utils/isEquals'
import { assignmentDeleteAction, assignmentPureListFetch } from '../../action/assignmentActions'
import AssignmentList from '../../components/Assignment/AssignmentList'
import { fields } from '../../components/Assignment/AssignmentListFilterForm'

const PICK_PARAMS = [
  ...DEFAULT_PICK_PARAMS,
  ...fields
]
const AssignmentListContainer = props => {
  const data = useFetchList({
    action: assignmentPureListFetch,
    stateName: stateNames.ASSIGNMENT_PURE_LIST,
    pickParams: PICK_PARAMS
  })
  const deleteData = useDelete({
    stateName: stateNames.ASSIGNMENT_DELETE,
    action: assignmentDeleteAction,
    successAction: assignmentPureListFetch
  })

  const filterAction = useFilterActions({ fields })

  const onEdit = (id) => props.history.push(sprintf(ROUTES.ASSIGNMENT_UPDATE_URL, id))
  return (
    <AssignmentList
      data={data}
      filterAction={filterAction}
      onEdit={onEdit}
      deleteData={deleteData}
    />
  )
}

export default AssignmentListContainer
