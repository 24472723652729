import * as stateNames from 'constants/stateNames'
import * as ROUTES from 'constants/routes'
import { CLINICS_ITEM } from 'constants/actionTypes'
import React from 'react'
import { path } from 'ramda'
import Layout from 'components/Layouts/Layout'
import { useFetchItem, useUpdate } from 'hooks'
import { useDispatch } from 'react-redux'
import ClinicUpdate from '../../components/Clinic/ClinicUpdate'
import { clinicItemFetch, clinicUpdateAction } from '../../action/clinicActions'
import { clinicsCreateSerializer } from '../../serializers/clinicSerializer'

const ClinicUpdateContainer = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    return () => {
      dispatch({ type: CLINICS_ITEM + '_CLEAR' })
    }
  }, [])
  const clinicItem = useFetchItem<any>({
    action: clinicItemFetch,
    stateName: stateNames.CLINICS_ITEM
  })

  const update = useUpdate({
    action: clinicUpdateAction,
    stateName: stateNames.CLINICS_UPDATE,
    redirectUrl: ROUTES.CLINIC_LIST_PATH,
    serializer: clinicsCreateSerializer
  })

  const data = path<any>(['data', 'data'], clinicItem)
  const initialValues = {
    ...data,
    endTime: path(['workingTime', '0', 'endTime'], data),
    startTime: path(['workingTime', '0', 'startTime'], data)
  }
  return (
    <Layout>
      <ClinicUpdate
        {...update}
        item={clinicItem}
        itemLoading={clinicItem.loading}
        initialValues={initialValues}
      />
    </Layout>
  )
}

export default ClinicUpdateContainer
