import { combineReducers } from 'redux'
import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux'
import { compose, forEach, toPairs } from 'ramda'
import { TOrderItem } from 'types/models'
import { AsyncReducers, TGetDataFromState } from 'types'
import createThunkReducer, { createGetThunkReducer, createPostThunkReducer } from '../utils/createThunkReducer'
import * as actionTypes from '../constants/actionTypes'
import * as stateNames from '../constants/stateNames'
import confirmDialogReducer from '../components/ConfirmDialog/reducer'

export type RootState = {
  orderList: TGetDataFromState<TOrderItem>;
  login: TGetDataFromState<any>;
  userInfo: any;
}

export const makeRootReducer = (asyncReducers: AsyncReducers) =>
  combineReducers({
    confirmDialog: confirmDialogReducer,
    [stateNames.LOGIN]: createPostThunkReducer(actionTypes.LOGIN),
    [stateNames.USER_INFO]: createThunkReducer(actionTypes.USER_INFO),

    [stateNames.CLIENT_LIST]: createThunkReducer(actionTypes.CLIENT_LIST),
    [stateNames.CLIENT_ITEM]: createThunkReducer(actionTypes.CLIENT_ITEM),
    [stateNames.CLIENT_CREATE]: createThunkReducer(actionTypes.CLIENT_CREATE),
    [stateNames.CLIENT_UPDATE]: createThunkReducer(actionTypes.CLIENT_UPDATE),
    [stateNames.CLIENT_DELETE]: createThunkReducer(actionTypes.CLIENT_DELETE),

    [stateNames.CLINICS_LIST]: createThunkReducer(actionTypes.CLINICS_LIST),
    [stateNames.CLINICS_ITEM]: createThunkReducer(actionTypes.CLINICS_ITEM),
    [stateNames.CLINICS_CREATE]: createThunkReducer(actionTypes.CLINICS_CREATE),
    [stateNames.CLINICS_UPDATE]: createThunkReducer(actionTypes.CLINICS_UPDATE),
    [stateNames.CLINICS_DELETE]: createThunkReducer(actionTypes.CLINICS_DELETE),

    [stateNames.CLIENT_CONTACT_LIST]: createThunkReducer(actionTypes.CLIENT_CONTACT_LIST),
    [stateNames.CLIENT_CONTACT_ITEM]: createThunkReducer(actionTypes.CLIENT_CONTACT_ITEM),
    [stateNames.CLIENT_CONTACT_CREATE]: createThunkReducer(actionTypes.CLIENT_CONTACT_CREATE),
    [stateNames.CLIENT_CONTACT_UPDATE]: createThunkReducer(actionTypes.CLIENT_CONTACT_UPDATE),
    [stateNames.CLIENT_CONTACT_DELETE]: createThunkReducer(actionTypes.CLIENT_CONTACT_DELETE),

    [stateNames.ASSIGNMENT_LIST]: createThunkReducer(actionTypes.ASSIGNMENT_LIST),
    [stateNames.ASSIGNMENT_PURE_LIST]: createThunkReducer(actionTypes.ASSIGNMENT_PURE_LIST),
    [stateNames.ASSIGNMENT_CREATE]: createPostThunkReducer(actionTypes.ASSIGNMENT_CREATE),
    [stateNames.ASSIGNMENT_ITEM]: createThunkReducer(actionTypes.ASSIGNMENT_ITEM),
    [stateNames.ASSIGNMENT_UPDATE]: createPostThunkReducer(actionTypes.ASSIGNMENT_UPDATE),
    [stateNames.ASSIGNMENT_DELETE]: createPostThunkReducer(actionTypes.ASSIGNMENT_DELETE),

    [stateNames.ASSIGNMENT_SUBMITTED_LIST]: createThunkReducer(actionTypes.ASSIGNMENT_SUBMITTED_LIST),
    [stateNames.ASSIGNMENT_SUBMITTED_ITEM]: createThunkReducer(actionTypes.ASSIGNMENT_SUBMITTED_ITEM),
    [stateNames.ASSIGNMENT_APPROVED_LIST]: createThunkReducer(actionTypes.ASSIGNMENT_APPROVED_LIST),

    [stateNames.BANK_ACCOUNT_LIST]: createThunkReducer(actionTypes.BANK_ACCOUNT_LIST),
    [stateNames.BANK_ACCOUNT_CREATE]: createPostThunkReducer(actionTypes.BANK_ACCOUNT_CREATE),
    [stateNames.BANK_ACCOUNT_ITEM]: createThunkReducer(actionTypes.BANK_ACCOUNT_ITEM),
    [stateNames.BANK_ACCOUNT_UPDATE]: createPostThunkReducer(actionTypes.BANK_ACCOUNT_UPDATE),
    [stateNames.BANK_ACCOUNT_DELETE]: createPostThunkReducer(actionTypes.BANK_ACCOUNT_DELETE),

    [stateNames.CONTRACT_LIST]: createThunkReducer(actionTypes.CONTRACT_LIST),
    [stateNames.CONTRACT_CREATE]: createThunkReducer(actionTypes.CONTRACT_CREATE),
    [stateNames.CONTRACT_ITEM]: createThunkReducer(actionTypes.CONTRACT_ITEM),
    [stateNames.CONTRACT_UPDATE]: createThunkReducer(actionTypes.CONTRACT_UPDATE),
    [stateNames.CONTRACT_DELETE]: createThunkReducer(actionTypes.CONTRACT_DELETE),
    [stateNames.CONTRACT_ID]: createThunkReducer(actionTypes.CONTRACT_ID),

    [stateNames.CONTRACT_CONFIG_LIST]: createThunkReducer(actionTypes.CONTRACT_CONFIG_LIST),
    [stateNames.CONTRACT_CONFIG_CREATE]: createThunkReducer(actionTypes.CONTRACT_CONFIG_CREATE),
    [stateNames.CONTRACT_CONFIG_ITEM]: createThunkReducer(actionTypes.CONTRACT_CONFIG_ITEM),
    [stateNames.CONTRACT_CONFIG_BILLING_ITEM]: createThunkReducer(actionTypes.CONTRACT_CONFIG_BILLING_ITEM),

    [stateNames.EXPENSE_LIST]: createThunkReducer(actionTypes.EXPENSE_LIST),
    [stateNames.EXPENSE_ALL_LIST]: createThunkReducer(actionTypes.EXPENSE_ALL_LIST),
    [stateNames.EXPENSE_CREATE]: createPostThunkReducer(actionTypes.EXPENSE_CREATE),
    [stateNames.EXPENSE_ITEM]: createThunkReducer(actionTypes.EXPENSE_ITEM),
    [stateNames.EXPENSE_UPDATE]: createPostThunkReducer(actionTypes.EXPENSE_UPDATE),
    [stateNames.EXPENSE_DELETE]: createPostThunkReducer(actionTypes.EXPENSE_DELETE),
    [stateNames.EXPENSE_STATUS_CHANGE]: createPostThunkReducer(actionTypes.EXPENSE_STATUS_CHANGE),

    [stateNames.FEE_LIST]: createThunkReducer(actionTypes.FEE_LIST),
    [stateNames.FEE_TIMER_LIST]: createThunkReducer(actionTypes.FEE_TIMER_LIST),
    [stateNames.FEE_TIMER_ITEM]: createThunkReducer(actionTypes.FEE_TIMER_ITEM),
    [stateNames.FEE_TIMER_FINISHED_LIST]: createThunkReducer(actionTypes.FEE_TIMER_FINISHED_LIST),
    [stateNames.FEE_ALL_LIST]: createThunkReducer(actionTypes.FEE_ALL_LIST),
    [stateNames.FEE_CREATE]: createPostThunkReducer(actionTypes.FEE_CREATE),
    [stateNames.FEE_ITEM]: createThunkReducer(actionTypes.FEE_ITEM),
    [stateNames.FEE_UPDATE]: createPostThunkReducer(actionTypes.FEE_UPDATE),
    [stateNames.FEE_DELETE]: createPostThunkReducer(actionTypes.FEE_DELETE),
    [stateNames.FEE_ITEM_DELETE]: createPostThunkReducer(actionTypes.FEE_ITEM_DELETE),
    [stateNames.FEE_STATUS_CHANGE]: createPostThunkReducer(actionTypes.FEE_STATUS_CHANGE),
    [stateNames.FEE_TIMER_CHANGE_STATUS]: createPostThunkReducer(actionTypes.FEE_TIMER_CHANGE_STATUS),
    [stateNames.FEE_TIMER_CREATE]: createPostThunkReducer(actionTypes.FEE_TIMER_CREATE),
    [stateNames.FEE_TIMER_PAUSE]: createPostThunkReducer(actionTypes.FEE_TIMER_PAUSE),
    [stateNames.FEE_TIMER_PLAY]: createPostThunkReducer(actionTypes.FEE_TIMER_PLAY),
    [stateNames.FEE_TIMER_FINISH]: createPostThunkReducer(actionTypes.FEE_TIMER_FINISH),
    [stateNames.FEE_TIMER_ACTIVE]: createPostThunkReducer(actionTypes.FEE_TIMER_ACTIVE),
    [stateNames.FEE_PERIOD_LIST]: createPostThunkReducer(actionTypes.FEE_PERIOD_LIST),

    [stateNames.OUTSOURCE_LIST]: createGetThunkReducer(actionTypes.OUTSOURCE_LIST),
    [stateNames.OUTSOURCE_CREATE]: createPostThunkReducer(actionTypes.OUTSOURCE_CREATE),
    [stateNames.OUTSOURCE_ITEM]: createGetThunkReducer(actionTypes.OUTSOURCE_ITEM),
    [stateNames.OUTSOURCE_UPDATE]: createPostThunkReducer(actionTypes.OUTSOURCE_UPDATE),
    [stateNames.OUTSOURCE_DELETE]: createPostThunkReducer(actionTypes.OUTSOURCE_DELETE),

    [stateNames.POSITION_LIST]: createThunkReducer(actionTypes.POSITION_LIST),
    [stateNames.POSITION_CREATE]: createThunkReducer(actionTypes.POSITION_CREATE),
    [stateNames.POSITION_ITEM]: createThunkReducer(actionTypes.POSITION_ITEM),
    [stateNames.POSITION_UPDATE]: createThunkReducer(actionTypes.POSITION_UPDATE),
    [stateNames.POSITION_DELETE]: createThunkReducer(actionTypes.POSITION_DELETE),

    [stateNames.STAFF_LIST]: createThunkReducer(actionTypes.STAFF_LIST),
    [stateNames.STAFF_CREATE]: createThunkReducer(actionTypes.STAFF_CREATE),
    [stateNames.STAFF_ITEM]: createThunkReducer(actionTypes.STAFF_ITEM),
    [stateNames.STAFF_UPDATE]: createThunkReducer(actionTypes.STAFF_UPDATE),
    [stateNames.STAFF_DELETE]: createThunkReducer(actionTypes.STAFF_DELETE),

    [stateNames.CASHBOX_LIST]: createThunkReducer(actionTypes.CASHBOX_LIST),
    [stateNames.CASHBOX_CREATE]: createThunkReducer(actionTypes.CASHBOX_CREATE),
    [stateNames.CASHBOX_ITEM]: createThunkReducer(actionTypes.CASHBOX_ITEM),
    [stateNames.CASHBOX_UPDATE]: createThunkReducer(actionTypes.CASHBOX_UPDATE),
    [stateNames.CASHBOX_DELETE]: createThunkReducer(actionTypes.CASHBOX_DELETE),

    [stateNames.FINANCE_CASHBOX_LIST]: createThunkReducer(actionTypes.FINANCE_CASHBOX_LIST),
    [stateNames.FINANCE_CASHBOX_TOTAL]: createThunkReducer(actionTypes.FINANCE_CASHBOX_TOTAL),

    [stateNames.FINANCE_CASHBOX_DETAIL_LIST]: createThunkReducer(actionTypes.FINANCE_CASHBOX_DETAIL_LIST),
    [stateNames.FINANCE_CASHBOX_DETAIL_TOTAL]: createThunkReducer(actionTypes.FINANCE_CASHBOX_DETAIL_TOTAL),

    [stateNames.TRANSACTIONS_LIST]: createThunkReducer(actionTypes.TRANSACTIONS_LIST),
    [stateNames.TRANSACTIONS_CREATE]: createThunkReducer(actionTypes.TRANSACTIONS_CREATE),
    [stateNames.TRANSACTIONS_ITEM]: createThunkReducer(actionTypes.TRANSACTIONS_ITEM),
    [stateNames.TRANSACTIONS_UPDATE]: createThunkReducer(actionTypes.TRANSACTIONS_UPDATE),
    [stateNames.TRANSACTIONS_DELETE]: createThunkReducer(actionTypes.TRANSACTIONS_DELETE),

    [stateNames.INVOICE_DELETE]: createThunkReducer(actionTypes.INVOICE_DELETE),
    [stateNames.TRANSACTIONS_PENDING_LIST]: createThunkReducer(actionTypes.TRANSACTIONS_PENDING_LIST),
    [stateNames.TRANSACTIONS_PENDING_CREATE]: createThunkReducer(actionTypes.TRANSACTIONS_PENDING_CREATE),
    [stateNames.TRANSACTIONS_PENDING_ITEM]: createThunkReducer(actionTypes.TRANSACTIONS_PENDING_ITEM),
    [stateNames.TRANSACTIONS_PENDING_UPDATE]: createThunkReducer(actionTypes.TRANSACTIONS_PENDING_UPDATE),
    [stateNames.TRANSACTIONS_PENDING_DELETE]: createThunkReducer(actionTypes.TRANSACTIONS_PENDING_DELETE),

    ...asyncReducers
  })

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export const injectReducers = (store, reducers) =>
  compose(
    forEach(([key, reducer]) => injectReducer(store, { key, reducer })),
    toPairs
  )(reducers)

type ThunkResult<R> = {
  type: string;
  value: Record<string, any>;
}
export type PromiseThunksResult = (action: any) => Promise<ThunkResult<any>>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
export const usePromiseDispatch: () => (action: any) => Promise<any> = useDispatch
