import * as stateNames from 'constants/stateNames'
import React from 'react'

import {
  useFetchItem
} from 'hooks'

import {
  assignmentItemFetch,
} from '../../action/assignmentActions'
import AssignmentDetail from '../../components/Assignment/AssignmentDetails/AssignmentDetail'

const AssignmentDetailContainer = () => {
  const data = useFetchItem<any>({
    action: assignmentItemFetch,
    stateName: stateNames.ASSIGNMENT_ITEM
  })

  return (
    <AssignmentDetail
      data={data}
    />
  )
}
export default AssignmentDetailContainer
