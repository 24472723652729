import { EMPTY_ARR } from 'constants/usefulConstants'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Field } from 'react-final-form'
import { path, map } from 'ramda'
import {
  Table,
  TableRow,
  TableCol,
  TableHeader,
  TableBody, TableColRight
} from 'components/Table'
import { TExpenseItem } from 'types'
import { Tag } from 'components/StyledElems'
import { ApprovalStatus } from 'components/UI'
import dateFormat from 'utils/dateFormat'
import numberFormat from 'utils/numberFormat'
import { InputField } from 'components/Form'

type Props = {
  list: TExpenseItem[];
  showType?: boolean;
  rejectMode?: boolean;

}
const HeaderRow = styled(TableRow)`

`
const InputFieldUI = styled(InputField)`
    height: 32px;
    border-radius: 4px;
    font-size: 13px;
    padding: 0 10px;
`

const ExpenseSelectListField: FunctionComponent<Props> = props => {
  const { list, showType, rejectMode } = props

  const ids = map(path<number>(['id']), list)

  return (
    <Field
      name="expenses"
      render={({ input }) => {
        const onChange = (selected: number[]) => input.onChange(selected)
        const tableActions = { onSelect: onChange, initialSelected: EMPTY_ARR }
        return (
          <div>
            <Table gutter={20} list={ids} selection={true} tableActions={tableActions}>
              <TableHeader>
                <HeaderRow align="center">
                  <TableCol span={4}>Date</TableCol>
                  <TableCol span={5} />
                  <TableCol span={8}>Description</TableCol>
                  <TableColRight span={4}>Cost</TableColRight>
                  <>
                    {rejectMode && <TableColRight span={4}>
                      Comment
                    </TableColRight>}
                  </>
                </HeaderRow>
              </TableHeader>
              <TableBody>
                {list.map(fee => {
                  const currency = path(['assignment', 'currency', 'name'], fee)
                  return (
                    <TableRow align="center" key={fee.id} selectId={fee.id}>
                      <TableCol span={4}>{dateFormat(fee.date)}</TableCol>
                      <TableCol span={5}>
                        {showType && <Tag>Fee</Tag>}
                        <ApprovalStatus>{fee.statusApprovement}</ApprovalStatus>
                      </TableCol>
                      <TableCol span={8}>{fee.description}</TableCol>
                      <TableColRight span={4}>{numberFormat(fee.amount, currency)}</TableColRight>
                      <>
                        {rejectMode && <TableColRight span={4}>
                          <Field
                            name={`comment.${fee.id}`}
                            component={InputFieldUI}
                          />
                        </TableColRight>}
                      </>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        )
      }}
    />

  )
}
ExpenseSelectListField.defaultProps = {
  showType: false
}
export default ExpenseSelectListField
