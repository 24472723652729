import styled from 'styled-components'
import animation from 'components/Skeletons/animation'
import { GREY } from 'components/Skeletons/colors'

type Props = {
  width?: string;
  height?: string;
}
const Loader = styled('div')<Props>`
  animation: ${animation};
  background-color: ${GREY};
  border-radius: 8px;
  height: ${props => props.height};
  width: ${props => props.width};
`

Loader.defaultProps = {
  width: '100%',
  height: '20px'
}
export default Loader
