import React, { FunctionComponent } from 'react'
import { Col } from 'components/UI/Grid'
import { InputLabel } from 'components/UI'
import styled from 'styled-components'

type Props = {
  header?: boolean;
  span: number;
  children: any;
}

const Label = styled(InputLabel)`
 margin: auto;
`
const ColUI = styled(Col)`
  word-break: break-all;
`
const DetailTableCol: FunctionComponent<Props> = props => {
  const { header, children, ...rest } = props
  if (header) {
    return (
      <ColUI {...rest}><Label>{children}</Label></ColUI>
    )
  }

  return <ColUI {...rest}>{children}</ColUI>
}

export const DetailTableColRight = styled(DetailTableCol)`
  text-align: right;
`
export default DetailTableCol
