import React from 'react'
import { propOr } from 'ramda'
import { Field } from 'react-final-form'
import { InputField } from 'components/Form'
import { Table, TableBody, TableCol, TableColRight, TableHeader, TableRow } from 'components/Table'
import RemoveButton from 'components/Form/FieldArray/RemoveButton'
import FieldArrayHeader from 'components/Form/FieldArray/FieldArrayHeader'

const TimeListField = props => {
  const { fields, isUpdate, onCreate } = props
  const onAdd = () => isUpdate ? onCreate() : fields.push({})
  const onRemove = index => fields.remove(index)
  const values: [] = propOr([], 'value', fields)
  return (
    <div>
      {!isUpdate && <FieldArrayHeader title="Время" onAdd={onAdd} />}
      <Table gutter={20}>
        <TableHeader>
          <TableRow align="center">
            <TableCol span={8}>Начало</TableCol>
            <TableCol span={8}>Окончание</TableCol>
            <TableCol span={1} />
          </TableRow>
        </TableHeader>
        <TableBody>
          {values.map((fee, index) => {
            return (
              <TableRow align="center" key={index}>
                <TableCol span={4}>
                  <Field
                    component={InputField}
                    name={`workingTime[${index}].startTime`}
                    type={'time'}
                  />
                </TableCol>
                <TableCol span={4}>
                  <Field
                    component={InputField}
                    name={`workingTime[${index}].endTime`}
                    type={'time'}
                  />
                </TableCol>
                <TableColRight span={1}>
                  <RemoveButton onRemove={() => onRemove(index)} />
                </TableColRight>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default TimeListField
