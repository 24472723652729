import React, { FunctionComponent } from 'react'
import {
  Form
} from 'react-final-form'
import { DetailMenu } from 'components/Menu'
import { Box } from 'components/UI'
import { TData, TGetDataFromState, TOnSubmit, TPositionItem } from 'types'
import { Merge } from 'types/utils'
import { DrawerSkeleton } from 'components/Skeletons'
import { path } from 'ramda'
import arrayMutators from 'final-form-arrays'
import ClinicCreateForm from './ClinicCreateForm'

type Props = {
  onSubmit: TOnSubmit;
  initialValues: Record<string, any>;
  id: string;
  itemLoading: boolean;
  item: TGetDataFromState<any>;
  positionData: TGetDataFromState<TData<TPositionItem>>;
}

type NewPropType = Merge<TGetDataFromState<any | null>, Props>

const ClinicUpdate: FunctionComponent<NewPropType> = props => {
  return (
    <div>
      <DetailMenu title={`Обновить ${path(['item', 'data', 'data', 'name'], props)}`} />
      <Box padding="25px">
        <DrawerSkeleton loading={props.itemLoading}>
          <Form
            onSubmit={props.onSubmit}
            initialValues={props.initialValues}
            mutators={{ ...arrayMutators }}
            render={formikProps => {
              return (
                <ClinicCreateForm
                  {...formikProps}
                />
              )
            }}
          />
        </DrawerSkeleton>
      </Box>
    </div>
  )
}

export default ClinicUpdate
