import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled('div')`  
`
const ToggleButton = styled('div')<{isOpen: boolean}>`
  cursor:pointer;
  font-weight: 500;
  text-align: center;
  padding: 15px;
  background-color: #FAFBFB;

`

const Content = styled('div')`
`

const SHOW_MORE = 'Show more'
const HIDE = 'Hide'
const ExpansionPanel = props => {
  const { children, isOpen, ...rest } = props

  const [isExpanded, setIsExpanded] = React.useState(isOpen)

  const toggleCollapse = () => setIsExpanded(!isExpanded)

  return (
    <Container {...rest}>
      {isExpanded && (
        <Content>{children}</Content>
      )}
      <ToggleButton isOpen={isExpanded} onClick={toggleCollapse}>
        {isExpanded ? HIDE : SHOW_MORE}
      </ToggleButton>
    </Container>
  )
}

ExpansionPanel.propTypes = {
  title: PropTypes.string,
  titleStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool
}

ExpansionPanel.defaultProps = {
  isOpen: false
}

export default ExpansionPanel
