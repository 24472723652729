import * as ROUTES from 'constants/routes'
import * as API from 'constants/api'
import { USER_STATUS_LIST, WEEKS } from 'constants/backend'
import React, { FunctionComponent } from 'react'
import { Field, FormRenderProps } from 'react-final-form'
import { FieldWrapper, DoubleField } from 'components/StyledElems'
import {
  DateField,
  InputField,
  UniversalMultiSelectField,
} from 'components/Form'
import CreateCancelButtons from 'components/UI/Buttons/CreateCancelButtons'
import { Merge } from 'types'
import { propOr, pipe, pluck, join } from 'ramda'
import { normalizePhone } from 'utils/normalize'
import { FieldArray } from 'react-final-form-arrays'
import TimeListField from 'components/Form/FieldArray/TimeListField'
import UniversalStaticMultiSelect from 'components/Form/Select/UniversalStaticMultiSelect'
import UniversalStaticSelectField from '../../../../components/Form/Select/UniversalStaticSelectField'

type Props = Merge<FormRenderProps, {
}>;

const AssignmentCreateForm: FunctionComponent<Props> = props => {
  const { handleSubmit, values } = props
  const clinicIds = propOr([], 'clinicIds', values)
  const clinicIdsToParams = pipe(
    pluck('_id'),
    join(',')
  )(clinicIds)
  return (
    <form onSubmit={handleSubmit}>
      <DoubleField>
        <div>
          <FieldWrapper>
            <Field
              component={InputField}
              name={'email'}
              label={'Email'}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Field
              component={InputField}
              name={'password'}
              label={'Пароль'}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Field
              label="Клиники"
              name="clinicIds"
              api={API.CLINICS_LIST}
              component={UniversalMultiSelectField}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Field
              label="Права"
              name="roles"
              api={API.ROLES_LIST}
              component={UniversalMultiSelectField}
              parent={clinicIds}
              params={{ clinicIds: clinicIdsToParams }}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Field
              isStatic={true}
              label="Рабочие дни"
              name="workingDays"
              list={WEEKS}
              component={UniversalStaticMultiSelect}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FieldArray
              name="workingTime"
              component={TimeListField}
            />
          </FieldWrapper>
        </div>
        <div>
          <FieldWrapper>
            <Field
              label="Имя"
              name="firstName"
              component={InputField}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Field
              label="Фамилия"
              name="lastName"
              component={InputField}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Field
              label="Отчество"
              name="parentName"
              component={InputField}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Field
              label="Номер телефона"
              autoComplete="off"
              name={'phoneNumber'}
              defaultValue={'+998'}
              parse={normalizePhone}
              component={InputField}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Field
              component={UniversalStaticSelectField}
              name="status"
              label="Статус"
              list={USER_STATUS_LIST}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Field
              label="Дата блокировки"
              name="blockedDate"
              component={DateField}
            />
          </FieldWrapper>
        </div>
      </DoubleField>
      <CreateCancelButtons
        cancelPath={ROUTES.ASSIGNMENT_LIST_PATH}
        submitText="Сохранить"
      />
    </form>
  )
}

export default AssignmentCreateForm
