import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { path, prop, map, pathOr, pipe } from 'ramda'
import dateFormat from 'utils/dateFormat'
import numberFormat from 'utils/numberFormat'
import ContactPerson from 'components/UI/ContactPerson'
import { DisplayFlex, Tag, FlexBorderBottom } from 'components/StyledElems'
import {
  LabeledValue as LabeledValueUI,
  SubTitle
} from 'components/DetailComponents'
import {
  Box,
  InputLabel,
  Rate
} from 'components/UI'
import {
  TAssignmentItem, TContactItem,
  TGetDataFromState,
  TIdName,
  TRateItem,
} from 'types'
import toNormalCase from 'utils/toNormalCase'
import { getFullName } from 'utils/get'

export const CARD_WIDTH = '450px'

const LabeledValue = styled(LabeledValueUI)`
  min-width: unset;
`
const Border = styled.div`
  border-bottom: ${props => props.theme.border};
  margin-bottom: 12px;
  padding-bottom: 16px;
`
const HighLight = styled.span`
  color: #000
`
const BoxUI = styled(Box)`
  width: ${CARD_WIDTH};
  padding: 30px 25px;
  overflow: hidden;
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  position:relative;
`

const ContactWrapper = styled.div`
  border-bottom: ${props => props.theme.border};
  margin-bottom: 12px;
  padding-bottom: 16px;
`

const EMPTY_ARR = []

type Props = {
    data: TGetDataFromState<TAssignmentItem>;
}
const AssignmentDetailsInfo: FunctionComponent<Props> = props => {
  const {
    data
  } = props

  const details = prop('data', data)
  const client = path(['client', 'name'], details)
  const fullName = getFullName(prop('name', details))
  const contact = path<TContactItem>(['clientContact'], details)
  const serviceDeliveredTo = path(['serviceDeliveredTo', 'name'], details)
  const contractConfig = prop('contractConfig', details)
  const contract = path(['contractConfig', 'contract', 'contractNumber'], details)
  const branch = path(['branch', 'name'], details)
  const originatedBy = path(['originatedBy', 'fullName'], details)
  // const teamLeader = path(['teamLeader', 'fullName'], details)
  // const workGroups = pathOr<TStaffItem[]>(EMPTY_ARR, ['workGroup'], details)
  const billingType = path(['billingType'], contractConfig)
  const deadLine = path(['deadLine'], details)
  const bankAccount = path(['bankAccount', 'name'], details)
  const currency = path(['currency', 'name'], contractConfig)
  const fixedFeeAmount = numberFormat(path(['fixedFeeAmount'], contractConfig), currency)
  const hourlyFeeCeiling = prop('hourlyFeeCeiling', contractConfig)
  const expenseInFee = prop('fixedFeeExpensesIncludedInFee', contractConfig)

  const feeAmount = expenseInFee
    ? fixedFeeAmount + ', expenses are included'
    : fixedFeeAmount + ', expenses are not included'

  const paymentDuration = prop('paymentDuration', details)

  const tags = pipe(
    pathOr([], ['tags']),
    map((tag: TIdName) => (<Tag key={tag.id}>{tag.name}</Tag>))
  )(details)

  // const workGroupWidget = workGroups.map(staff => (
  //   <UserImg
  //     key={staff.id}
  //     src={path(['photo', 'file'], staff)}
  //     name={staff.fullName}
  //   />
  // ))

  const rates = pipe(
    pathOr(EMPTY_ARR, ['rates']),
    map((rate: TRateItem) => (<Rate key={rate.position.id} label={rate.position.name}>{rate.amountPerHour}/hr</Rate>))
  )(contractConfig)

  const paymentDestination = path(['paymentDestination', 'name'], details)
  return (
    <BoxUI>
      <DisplayFlex justifyContent="space-between">
        <SubTitle>Подробности</SubTitle>
      </DisplayFlex>
      <Border />
      <FlexBorderBottom justifyContent="space-between">
        <LabeledValue label="Имя">{fullName}</LabeledValue>
      </FlexBorderBottom>
      <FlexBorderBottom justifyContent="space-between">
        <LabeledValue label="Client">{client}</LabeledValue>
      </FlexBorderBottom>
      <ContactWrapper>
        <LabeledValue label="Client Contacts" />
        <ContactPerson contact={contact} />
      </ContactWrapper>
      <FlexBorderBottom justifyContent="space-between">
        {contract && <LabeledValue label="Contract">{contract}</LabeledValue>}
        <LabeledValue label="Engagement office">{branch}</LabeledValue>
      </FlexBorderBottom>
      <FlexBorderBottom justifyContent="space-between">
        {serviceDeliveredTo && <LabeledValue label="Service Provided To">{serviceDeliveredTo}</LabeledValue>}
        <LabeledValue labelMargin={5} label="Tags">{tags}</LabeledValue>
      </FlexBorderBottom>
      <FlexBorderBottom justifyContent="space-between">
        <LabeledValue label="Originated By">{originatedBy}</LabeledValue>
        <LabeledValue label="Deadline">{dateFormat(deadLine)}</LabeledValue>
      </FlexBorderBottom>
      {/*
        <FlexBorderBottom justifyContent='space-between'>
          <LabeledValue labelMargin={5} label='Work Group'>
            {workGroupWidget}
          </LabeledValue>
        </FlexBorderBottom>

*/}
      <FlexBorderBottom justifyContent="space-between">
        <LabeledValue label="Payment Destination">{paymentDestination}</LabeledValue>
        <LabeledValue label="Bank Account">{bankAccount}</LabeledValue>
      </FlexBorderBottom>
      <FlexBorderBottom justifyContent="space-between">
        <LabeledValue label="Billing Type">{toNormalCase(billingType)}</LabeledValue>
        {hourlyFeeCeiling && (
          <LabeledValue label="Budget">{numberFormat(hourlyFeeCeiling, currency)}</LabeledValue>

        )}
      </FlexBorderBottom>

      {billingType === 'fixed_fee' && (
        <FlexBorderBottom justifyContent="space-between">
          <LabeledValue label="Fee Amount">{feeAmount}</LabeledValue>
        </FlexBorderBottom>
      )}
      {billingType === 'hourly_billing' && (
        <>
          <LabeledValue label="hourly rates">{rates}</LabeledValue>
          <Border />
        </>
      )}
      <InputLabel>
        Payment expected in <HighLight>{paymentDuration} days</HighLight> after invoice delivery
      </InputLabel>
    </BoxUI>
  )
}

export default AssignmentDetailsInfo
