import * as stateNames from 'constants/stateNames'
import * as ROUTES from 'constants/routes'
import React from 'react'
import { sprintf } from 'sprintf-js'
import {
  useFetchList,
  useFilterActions,
  useDelete
} from 'hooks'

import { DEFAULT_PICK_PARAMS } from 'utils/isEquals'
import { clinicDeleteAction, clinicListAction } from '../../action/clinicActions'
import ClinicList from '../../components/Clinic/ClinicList'
import { fields } from '../../components/Clinic/ClinicListFilterForm'

const PICK_PARAMS = [
  ...DEFAULT_PICK_PARAMS,
  ...fields
]
const ClinicListContainer = props => {
  const data = useFetchList({
    action: clinicListAction,
    stateName: stateNames.CLINICS_LIST,
    pickParams: PICK_PARAMS
  })
  const deleteData = useDelete({
    stateName: stateNames.CLINICS_DELETE,
    action: clinicDeleteAction,
    successAction: clinicListAction
  })

  const filterAction = useFilterActions({ fields })

  const onEdit = (id) => props.history.push(sprintf(ROUTES.CLINIC_UPDATE_URL, id))
  return (
    <ClinicList
      data={data}
      filterAction={filterAction}
      onEdit={onEdit}
      deleteData={deleteData}
    />
  )
}

export default ClinicListContainer
