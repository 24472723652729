import React from 'react'
import styled from 'styled-components'
import { ChevronDown } from 'react-feather'
import { TClientBalanceItem } from 'types'
import { DisplayFlex } from 'components/StyledElems'
import { BorderedButtonSmall } from 'components/UI/Buttons'

const Container = styled('div')`  
  background-color: #F7F8FA;
  border-radius: 10px;
  overflow: hidden;
`

const Content = styled('div')<{open?: boolean}>`
  padding: 0 15px;
  max-height: ${props => props.open ? '100px' : '0px'};
  overflow: hidden;
  transition: max-height 200ms;

`

const ToggleButton = styled('div')<{isOpen: boolean}>`
  cursor:pointer;
  font-weight: 500;
  text-align: center;
  padding: 3px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :hover {
  background-color: #F5F6FD;
  }

`

const Detail = styled.div`
  padding: 7px 0;
`

const Label = styled.span`
  color: #9AA6AC;
  padding: 7px 0;
`
type Props = {
  clientBalance: TClientBalanceItem;
}
const ContractBalance = (props: Props) => {
  const { clientBalance, ...rest } = props
  const [isExpanded, setIsExpanded] = React.useState(false)

  const contractNumber = clientBalance.contract.contractNumber
  const balance = clientBalance.balance
  const toggleCollapse = () => setIsExpanded(!isExpanded)

  return (
    <Container {...rest}>
      <ToggleButton isOpen={isExpanded} onClick={toggleCollapse}>
        <Label>{contractNumber}</Label>
        <DisplayFlex alignItems="center">
          <Label>{balance}</Label>
          <ChevronDown />
        </DisplayFlex>
      </ToggleButton>

      <Content open={isExpanded}>
        <DisplayFlex justifyContent="space-between" alignItems="center">
          <div>
            <Detail><Label>Fees:</Label> 100</Detail>
            <Detail><Label>Expense: </Label> 200</Detail>
          </div>
          <BorderedButtonSmall>Proceed Payment</BorderedButtonSmall>
        </DisplayFlex>
      </Content>
    </Container>
  )
}

export default ContractBalance
