import { join, isEmpty, pipe, prop } from 'ramda'
import { FORM_ERROR } from 'final-form'
import { showToast } from 'components/Toast'

// const transform = pipe(prop('1'), map(item => Object.values(item)))
export const mapResponseToFormError = data => {
  const errors = prop('errors', data) || []
  if (errors && !isEmpty(errors)) {
    showToast({
      type: 'error',
      title: 'Ошибка',
      message: ''
    })
    const fieldErrors = errors.reduce((result, obj) => {
      result[obj.property] = obj.message
      return result
    }, {})
    return fieldErrors
  } else if (data?.message) {
    showToast({
      type: 'error',
      title: 'Ошибка',
      message: data?.message
    })
  } else {
    showToast({
      type: 'error',
      title: ' ',
      message: 'Ошибка'
    })
  }
  return {
    ...errors,
    [FORM_ERROR]: join(', ', errors)
  }
}

const toArray = err => {
  if (!err) {
    return []
  }

  if (Array.isArray(err)) {
    return err
  }

  return [err]
}
export const getFieldError = pipe(
  prop('submitError'),
  toArray,
  join(',')
)
