import React from 'react'
import PropTypes from 'prop-types'
import { ActionButtons } from 'components/StyledElems'
import { LinkSecondaryButton } from './LinkButton'
import { Button } from './index'

const CreateCancelButtons = props => {
  const {
    loading,
    onCancel,
    cancelPath,
    submitText,
    cancelText,
    marginTop,
    mode,
    children
  } = props

  return (
    <ActionButtons mode={mode} marginTop={marginTop}>
      {(cancelPath || onCancel) && (
        <LinkSecondaryButton
          type="button"
          to={cancelPath}
          onClick={onCancel}
        >
          {cancelText}
        </LinkSecondaryButton>
      )}
      <Button type="submit" data-cy="submit" loading={loading}>{submitText}</Button>
      {children}
    </ActionButtons>
  )
}

CreateCancelButtons.propTypes = {
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  cancelPath: PropTypes.string,
  submitText: PropTypes.string,
  marginTop: PropTypes.string,
  mode: PropTypes.oneOf([
    'default',
    'half'
  ])
}

CreateCancelButtons.defaultProps = {
  submitText: 'Сохраанить',
  cancelText: 'Отмена'
}

export default CreateCancelButtons
