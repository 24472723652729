export const LOGIN = 'login'
export const USER_INFO = 'userInfo'

export const ORDER_LIST = 'orderList'
export const ORDER_ITEM = 'orderItem'
export const ORDER_CREATE = 'orderCreate'
export const ORDER_UPDATE = 'orderUpdate'
export const ORDER_DELETE = 'orderDelete'

export const CLIENT_LIST = 'clientList'
export const CLIENT_ITEM = 'clientItem'
export const CLIENT_CREATE = 'clientCreate'
export const CLIENT_UPDATE = 'clientUpdate'
export const CLIENT_DELETE = 'clientDelete'

export const CLIENT_CONTACT_LIST = 'clientContactList'
export const CLIENT_CONTACT_ITEM = 'clientContactItem'
export const CLIENT_CONTACT_CREATE = 'clientContactCreate'
export const CLIENT_CONTACT_UPDATE = 'clientContactUpdate'
export const CLIENT_CONTACT_DELETE = 'clientContactDelete'

export const OUTSOURCE_LIST = 'timeSheetList'
export const OUTSOURCE_ITEM = 'timeSheetItem'
export const OUTSOURCE_CREATE = 'timeSheetCreate'
export const OUTSOURCE_UPDATE = 'timeSheetUpdate'
export const OUTSOURCE_DELETE = 'timeSheetDelete'

export const OUTSOURCE_CONTACT_LIST = 'outsourceContactList'
export const OUTSOURCE_CONTACT_ITEM = 'outsourceContactItem'
export const OUTSOURCE_CONTACT_CREATE = 'outsourceContactCreate'
export const OUTSOURCE_CONTACT_UPDATE = 'outsourceContactUpdate'
export const OUTSOURCE_CONTACT_DELETE = 'outsourceContactDelete'

export const STAFF_LIST = 'staffList'
export const STAFF_ITEM = 'staffItem'
export const STAFF_CREATE = 'staffCreate'
export const STAFF_UPDATE = 'staffUpdate'
export const STAFF_DELETE = 'staffDelete'

export const TAGS_LIST = 'tagsList'
export const TAGS_ITEM = 'tagsItem'
export const TAGS_CREATE = 'tagsCreate'
export const TAGS_UPDATE = 'tagsUpdate'
export const TAGS_DELETE = 'tagsDelete'

export const TEAMS_LIST = 'teamsList'
export const TEAMS_ITEM = 'teamsItem'
export const TEAMS_CREATE = 'teamsCreate'
export const TEAMS_UPDATE = 'teamsUpdate'
export const TEAMS_DELETE = 'teamsDelete'

export const PAYMENTS_LIST = 'paymentsList'
export const PAYMENTS_ITEM = 'paymentsItem'
export const PAYMENTS_CREATE = 'paymentsCreate'
export const PAYMENTS_UPDATE = 'paymentsUpdate'
export const PAYMENTS_DELETE = 'paymentsDelete'

export const CASHBOX_LIST = 'cashboxList'
export const CASHBOX_ITEM = 'cashboxItem'
export const CASHBOX_CREATE = 'cashboxCreate'
export const CASHBOX_UPDATE = 'cashboxUpdate'
export const CASHBOX_DELETE = 'cashboxDelete'

export const FINANCE_CASHBOX_LIST = 'financeCashboxList'
export const FINANCE_CASHBOX_TOTAL = 'financeCashboxTotal'

export const FINANCE_CASHBOX_DETAIL_LIST = 'financeCashboxDetailList'
export const FINANCE_CASHBOX_DETAIL_TOTAL = 'financeCashboxDetailTotal'

export const TRANSACTIONS_LIST = 'transactionsList'
export const TRANSACTIONS_ITEM = 'transactionsItem'
export const TRANSACTIONS_CREATE = 'transactionsCreate'
export const TRANSACTIONS_UPDATE = 'transactionsUpdate'
export const TRANSACTIONS_DELETE = 'transactionsDelete'

export const TRANSACTIONS_PENDING_LIST = 'transactionsPendingList'
export const TRANSACTIONS_PENDING_ITEM = 'transactionsPendingItem'
export const TRANSACTIONS_PENDING_CREATE = 'transactionsPendingCreate'
export const TRANSACTIONS_PENDING_UPDATE = 'transactionsPendingUpdate'
export const TRANSACTIONS_PENDING_DELETE = 'transactionsPendingDelete'

export const CURRENCY_LIST = 'currencyList'
export const CURRENCY_ITEM = 'currencyItem'
export const CURRENCY_CREATE = 'currencyCreate'
export const CURRENCY_UPDATE = 'currencyUpdate'
export const CURRENCY_DELETE = 'currencyDelete'

export const BRANCH_LIST = 'branchList'
export const BRANCH_ITEM = 'branchItem'
export const BRANCH_CREATE = 'branchCreate'
export const BRANCH_UPDATE = 'branchUpdate'
export const BRANCH_DELETE = 'branchDelete'

export const GROUP_LIST = 'groupList'
export const GROUP_ITEM = 'groupItem'
export const GROUP_CREATE = 'groupCreate'
export const GROUP_UPDATE = 'groupUpdate'
export const GROUP_DELETE = 'groupDelete'

export const PERMISSION_LIST = 'permissionList'
export const PERMISSION_ITEM = 'permissionItem'
export const PERMISSION_CREATE = 'permissionCreate'
export const PERMISSION_UPDATE = 'permissionUpdate'
export const PERMISSION_DELETE = 'permissionDelete'

export const CONTRACT_LIST = 'contractList'
export const CONTRACT_ITEM = 'contractItem'
export const CONTRACT_CREATE = 'contractCreate'
export const CONTRACT_UPDATE = 'contractUpdate'
export const CONTRACT_DELETE = 'contractDelete'

export const CONTRACT_ID = 'contractId'

export const CONTRACT_CONFIG_LIST = 'contractConfigList'
export const CONTRACT_CONFIG_ITEM = 'contractConfigItem'
export const CONTRACT_CONFIG_CREATE = 'contractConfigCreate'
export const CONTRACT_CONFIG_UPDATE = 'contractConfigUpdate'
export const CONTRACT_CONFIG_DELETE = 'contractConfigDelete'
export const CONTRACT_CONFIG_BILLING_ITEM = 'contractConfigBillingItem'

export const ASSIGNMENT_LIST = 'assignmentList'
export const ASSIGNMENT_PURE_LIST = 'assignmentPureList'
export const ASSIGNMENT_ITEM = 'assignmentItem'
export const ASSIGNMENT_CREATE = 'assignmentCreate'
export const ASSIGNMENT_UPDATE = 'assignmentUpdate'
export const ASSIGNMENT_DELETE = 'assignmentDelete'
export const ASSIGNMENT_COMPLETE = 'assignmentComplete'

export const ASSIGNMENT_SUBMITTED_LIST = 'assignmentSubmittedList'
export const ASSIGNMENT_APPROVED_LIST = 'assignmentApprovedList'
export const ASSIGNMENT_SUBMITTED_ITEM = 'assignmentSubmittedItem'

export const POSITION_LIST = 'positionList'
export const POSITION_ITEM = 'positionItem'
export const POSITION_CREATE = 'positionCreate'
export const POSITION_UPDATE = 'positionUpdate'
export const POSITION_DELETE = 'positionDelete'

export const ROLE_LIST = 'roleList'
export const ROLE_ITEM = 'roleItem'
export const ROLE_CREATE = 'roleCreate'
export const ROLE_UPDATE = 'roleUpdate'
export const ROLE_DELETE = 'roleDelete'

export const BANK_ACCOUNT_LIST = 'bankAccountList'
export const BANK_ACCOUNT_ITEM = 'bankAccountItem'
export const BANK_ACCOUNT_CREATE = 'bankAccountCreate'
export const BANK_ACCOUNT_UPDATE = 'bankAccountUpdate'
export const BANK_ACCOUNT_DELETE = 'bankAccountDelete'

export const FEE_LIST = 'feeList'
export const FEE_TIMER_LIST = 'feeTimerList'
export const FEE_TIMER_ITEM = 'feeTimerItem'
export const FEE_TIMER_FINISHED_LIST = 'feeTimerFinishedList'
export const FEE_ALL_LIST = 'feeAllList'
export const FEE_ITEM = 'feeItem'
export const FEE_CREATE = 'feeCreate'
export const FEE_UPDATE = 'feeUpdate'
export const FEE_DELETE = 'feeDelete'
export const FEE_ITEM_DELETE = 'feeItemDelete'
export const FEE_STATUS_CHANGE = 'feeStatusChange'
export const FEE_STATUS_REJECT = 'feeStatusReject'

export const EXPENSE_LIST = 'expenseList'
export const EXPENSE_ALL_LIST = 'expenseAllList'
export const EXPENSE_ITEM = 'expenseItem'
export const EXPENSE_CREATE = 'expenseCreate'
export const EXPENSE_UPDATE = 'expenseUpdate'
export const EXPENSE_DELETE = 'expenseDelete'
export const EXPENSE_STATUS_CHANGE = 'expenseStatusChange'
export const EXPENSE_STATUS_REJECT = 'expenseStatusReject'
export const FEE_TIMER_CHANGE_STATUS = 'feeStatusChange'
export const FEE_TIMER_CREATE = 'feeTimeCreate'
export const FEE_TIMER_PAUSE = 'feeTimerPause'
export const FEE_TIMER_PLAY = 'feeTimerPlay'
export const FEE_TIMER_FINISH = 'feeTimerFinish'
export const FEE_TIMER_ACTIVE = 'feeTimerActive'
export const FEE_PERIOD_LIST = 'feePeriodList'

export const TIME_SHEET_LIST = 'timeSheetList'
export const TIME_SHEET_ITEM = 'timeSheetItem'
export const TIME_SHEET_CREATE = 'timeSheetCreate'
export const TIME_SHEET_UPDATE = 'timeSheetUpdate'
export const TIME_SHEET_DELETE = 'timeSheetDelete'

export const CLINICS_LIST = 'clinicsList'
export const CLINICS_ITEM = 'clinicsItem'
export const CLINICS_CREATE = 'clinicsCreate'
export const CLINICS_UPDATE = 'clinicsUpdate'
export const CLINICS_DELETE = 'clinicsDelete'

export const UNINVOICED_LIST = 'uninvoicedList'
export const UNINVOICED_ITEM = 'uninvoicedItem'
export const UNINVOICED_CREATE = 'uninvoicedCreate'
export const UNINVOICED_UPDATE = 'uninvoicedUpdate'
export const UNINVOICED_DELETE = 'uninvoicedDelete'

export const INVOICE_LIST = 'invoiceList'
export const INVOICE_ITEM = 'invoiceItem'
export const INVOICE_CREATE = 'invoiceCreate'
export const INVOICE_UPDATE = 'invoiceUpdate'
export const INVOICE_DELETE = 'invoiceDelete'
export const INVOICE_PREPAYMENT = 'invoicePrepayment'
export const INVOICE_TOTAL_PAYMENT = 'invoiceTotalPayment'
export const INVOICE_MONTH_PAYMENT = 'invoiceMonthPayment'
export const INVOICE_PAYMENT = 'invoicePayment'
export const INVOICE_PAYMENT_LIST = 'invoicePaymentList'
export const INVOICE_BALANCE_PAYMENT = 'invoiceBalancePayment'
export const INVOICE_SEND = 'invoiceSend'
export const INVOICE_DELIVERED = 'invoiceDelivered'

export const RECURRING_INVOICE_LIST = 'recurringInvoiceList'
export const RECURRING_INVOICE_ITEM = 'recurringInvoiceItem'
export const RECURRING_INVOICE_CREATE = 'recurringInvoiceCreate'
export const RECURRING_INVOICE_UPDATE = 'recurringInvoiceUpdate'
export const RECURRING_INVOICE_DELETE = 'recurringInvoiceDelete'
