import * as STATE from 'constants/stateNames'
import { useTypedSelector } from 'etc/reducers'
import { getDataFromState } from 'utils/getTyped'
import { path } from 'ramda'
import { TUserInfo } from 'types'

const useUserInfo = () => {
  const user = useTypedSelector(state => getDataFromState(STATE.USER_INFO, state))

  return path<TUserInfo>(['data'], user)
}

export default useUserInfo
