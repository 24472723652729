import React from 'react'
import {
  Button,
  BorderedButton,
  SecondaryButton as SecondaryButtonCUI,
  DangerButton
} from 'ui-cubic/dist/index.es'

import { LinkSecondaryButton, default as LinkButton } from './LinkButton'
import PlusButton from './PlusButton'
import CreateCancelButtons from './CreateCancelButtons'

const SecondaryButton = props => <SecondaryButtonCUI type="button" {...props} />

export {
  Button,
  BorderedButton,
  DangerButton,
  SecondaryButton,
  LinkButton,
  LinkSecondaryButton,
  PlusButton,
  CreateCancelButtons
}

export {
  default as ButtonSmall,
  BorderedButtonSmall,
  SecondaryButtonSmall,
  PrimaryBorderedButtonSmall
} from './ButtonSmall'
