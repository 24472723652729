import React, { FunctionComponent } from 'react'
import { Form } from 'react-final-form'
import { DetailMenu } from 'components/Menu/index'
import { Box } from 'components/UI'

import { TGetDataFromState, TOnSubmit, Merge, TGroupItem } from 'types'

import isEqual from 'react-fast-compare'
import arrayMutators from 'final-form-arrays'
import AssignmentCreateForm from './AssignmentCreateForm'

type Props = {
  onSubmit: TOnSubmit;
}

type NewPropType = Merge<TGetDataFromState<TGroupItem | null>, Props>

export const fields = [
  'email',
  'password',
  'roles',
  'workingDays',
  'blockedDate',
  'workingTime',
  'clinicIds',
  'contract',
  'firstName',
  'status',
  'lastName',
  'parentName',
  'phoneNumber'
]
const ContractCreate: FunctionComponent<NewPropType> = props => {
  return (
    <div>
      <DetailMenu title="Создание пользователя" />
      <Box padding="25px">
        <Form
          onSubmit={props.onSubmit}
          mutators={{ ...arrayMutators }}
          render={formikProps => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <AssignmentCreateForm
              {...formikProps}
            />
          )}
        />
      </Box>
    </div>
  )
}

export default React.memo(ContractCreate, isEqual)
