// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./GothamPro.woff");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./GothamProMediumItalic.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./GothamProLight.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./GothamProLightItalic.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./GothamProItalic.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./GothamProMedium.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./GothamProBold.woff");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./GothamProBoldItalic.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("./GothamProBlack.woff");
var ___CSS_LOADER_URL_IMPORT_9___ = require("./GothamProBlackItalic.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
// Module
exports.push([module.id, "@font-face {\n  font-family: GothamPro;\n  font-style: normal;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff');\n}\n@font-face {\n  font-family: GothamPro;\n  font-style: italic;\n  font-weight: 500;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n}\n\n@font-face {\n  font-family: GothamPro;\n  font-style: normal;\n  font-weight: 300;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff');\n}\n@font-face {\n  font-family: GothamPro;\n  font-style: italic;\n  font-weight: 300;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff');\n}\n\n@font-face {\n  font-family: GothamPro;\n  font-style: italic;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff');\n}\n@font-face {\n  font-family: GothamPro;\n  font-style: normal;\n  font-weight: 500;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('woff');\n}\n@font-face {\n  font-family: GothamPro;\n  font-style: normal;\n  font-weight: 600;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('woff');\n}\n@font-face {\n  font-family: GothamPro;\n  font-style: italic;\n  font-weight: 600;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format('woff');\n}\n@font-face {\n  font-family: GothamPro;\n  font-style: normal;\n  font-weight: 700;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format('woff');\n}\n@font-face {\n  font-family: GothamPro;\n  font-style: italic;\n  font-weight: 700;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format('woff');\n}\n", ""]);
// Exports
module.exports = exports;
