import { Box } from 'components/UI'
import styled from 'styled-components'

const TotalCard = styled(Box)`
  padding: 20px;
  & p {
    margin: 0;
    font-weight: 500;
    color: #8F9BB0;
    margin-bottom: 10px;
  }
  & span {
    color: #36434E;
    font-weight: 500;
    line-height: 1.4;
    font-size: 18px;
    white-space: pre-line;
  }
`

export default TotalCard
