import React from 'react'
import styled from 'styled-components'
import { ChevronDown, Edit3, Trash2 as Trash } from 'react-feather'
import { TContactItem, TUseUpdate } from 'types'
import { Row, Col } from 'components/UI/Grid'
import { DangerButton, PrimaryBorderedButtonSmall } from 'components/UI/Buttons'
import { FieldWrapper } from 'components/StyledElems'
import { Field, Form } from 'react-final-form'
import { InputField } from 'components/Form'
import CreateCancelButtons from 'components/UI/Buttons/CreateCancelButtons'
import { ImgNameRight } from './UserImg'

const Container = styled('div')`  
  background-color: #F7F8FA;
  border-radius: 10px;
  overflow: hidden;
`

const Content = styled('div')<{open?: boolean}>`
  padding: 0 15px;
  max-height: ${props => props.open ? '100%' : '0px'};
  overflow: hidden;
  transition: max-height 200ms;
`
const ToggleButton = styled('div')<{isOpen: boolean}>`
  cursor:pointer;
  font-weight: 500;
  text-align: center;
  padding: 3px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :hover {
  background-color: #F5F6FD;
  }
`
const Detail = styled.div`
  padding: 7px;
`
const Position = styled(Detail)`
  color: ${props => props.theme.colors.primary.default};
`

const Label = styled.span`
  color: #9AA6AC
`
const Button = styled(PrimaryBorderedButtonSmall)`
  position: relative;
  font-size: 14px;
  height: 36px;
  padding: 0 20px;
  border-radius: 6px;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 20px;
`
const UpdateForm = styled.div`
  padding-top: 15px;
  padding-bottom: 20px;
`
const InputFieldSmall = styled(InputField)`
  padding: 0 10px;
  height: 37px;
`
const RowUI = styled(Row)`
  margin-bottom: 20px;
`
const DangerButtonSmall = styled(DangerButton)`
  position: relative;
  font-size: 14px;
  height: 36px;
  padding: 0 20px;
  border-radius: 6px;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: 10px;
`
const ContactActions = styled.div`
  display: flex;
  justify-content: flex-end;
`

type Props = {
  contact: TContactItem;
  update: TUseUpdate;
  onDeleteContact: (id) => void;
}

const ContactListClient = (props: Props) => {
  const { contact, update, onDeleteContact, ...rest } = props
  const [isExpanded, setIsExpanded] = React.useState(false)
  const [isUpdate, setIsUpdate] = React.useState(false)

  if (!contact) {
    return null
  }
  const toggleCollapse = () => setIsExpanded(!isExpanded)

  return (
    <Container {...rest}>
      <ToggleButton isOpen={isExpanded} onClick={toggleCollapse}>
        <ImgNameRight src="" name={contact.name} /> <span><ChevronDown /></span>
      </ToggleButton>
      <Content open={isExpanded}>
        <RowUI>
          <Col>
            {isUpdate
              ? <UpdateForm>
                <Form
                  initialValues={contact}
                  onSubmit={(values) => update.onSubmit({ ...values, id: contact.id })}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <FieldWrapper>
                        <Field
                          label="Contact Person"
                          name="name"
                          component={InputFieldSmall}
                        />
                      </FieldWrapper>
                      <FieldWrapper>
                        <Field
                          label="Email"
                          name="email"
                          component={InputFieldSmall}
                          placeholder="Email"
                        />
                      </FieldWrapper>
                      <FieldWrapper>
                        <Field
                          label="Phone"
                          name="phone"
                          component={InputFieldSmall}
                        />
                      </FieldWrapper>
                      <FieldWrapper>
                        <Field
                          label="Position"
                          name="position"
                          component={InputFieldSmall}
                        />
                      </FieldWrapper>
                      <CreateCancelButtons
                        onCancel={() => setIsUpdate(!isUpdate)}
                        submitText="Save"
                        mode="half"
                        marginTop="0"
                      />
                    </form>
                  )}
                />
              </UpdateForm>
              : <><Position>{contact.position}</Position>
                <Detail><Label>Email:</Label> {contact.email}</Detail>
                <Detail><Label>Phone: </Label> {contact.phone}</Detail></>}
          </Col>
          <Col>
            <ContactActions>
              {!isUpdate && <Button onClick={() => setIsUpdate(!isUpdate)}>
                <Edit3/>
              </Button>}
              {!isUpdate &&
              <DangerButtonSmall type="button" onClick={() => onDeleteContact(contact.id)}>
                <Trash/>
              </DangerButtonSmall>}
            </ContactActions>
          </Col>
        </RowUI>
        <RowUI>
        </RowUI>
      </Content>
    </Container>
  )
}

export default ContactListClient
