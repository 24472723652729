import { getCookie } from 'utils/cookie'
import { paramsToSearch } from './urls'

export const getRedirectUrl = (url) => {
  // remove last slash
  const token = getCookie('token')
  const formedParams = paramsToSearch({ token })
  const link = `${url}?${formedParams}`
  return link
}
