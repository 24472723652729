import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mapResponseToFormError } from 'utils/form'
import { getDataFromState } from 'utils/get'
import * as ROUTES from '../../constants/routes'
import * as stateNames from '../../constants/stateNames'
import SignIn from './SignIn'
import { loginAction } from './action'

const SignInContainer = props => {
  const dispatch = useDispatch()
  const onLogin = (data) => {
    return dispatch(loginAction(data))
      .then(() => props.history.replace(ROUTES.ASSIGNMENT_LIST_PATH))
      .catch(mapResponseToFormError)
  }

  const loginData = useSelector(getDataFromState(stateNames.LOGIN))
  const userInfo = useSelector(getDataFromState(stateNames.USER_INFO))
  return (
    <SignIn onLogin={onLogin} userInfo={userInfo} loginData={loginData} />
  )
}

export default SignInContainer
