import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { prop } from 'ramda'
import { DetailMenu } from 'components/Menu'
import { TGetDataFromState } from 'types'
import { DrawerSkeleton } from 'components/Skeletons'
import AssignmentFeeExpenseList from './AssignmentFeeExpenseList'
import AssignmentDetailsInfo from './AssignmentDetailsInfo'

const CardWrapper = styled.div`
  display: flex;
  align-items: start;
`
type Props = {
  data: TGetDataFromState<any>;
}

const AssignmentDetail: FunctionComponent<Props> = props => {
  const {
    data,
  } = props

  const details: any = prop('data', data)
  const code = prop('code', details)

  return (
    <>
      <DetailMenu title={'Assignment details - ' + code} />
      <CardWrapper>
        <AssignmentFeeExpenseList
          details={details}
        />
        <DrawerSkeleton loading={data.loading}>
          <AssignmentDetailsInfo data={data} />
        </DrawerSkeleton>
      </CardWrapper>
    </>
  )
}

export default AssignmentDetail
