import React from 'react'
import styled from 'styled-components'
import { ChevronDown } from 'react-feather'
import { TContactItem } from 'types'
import { ImgNameRight } from './UserImg'

const Container = styled('div')`  
  background-color: #F7F8FA;
  border-radius: 10px;
  overflow: hidden;
`

const Content = styled('div')<{open?: boolean}>`
  padding: 0 15px;
  max-height: ${props => props.open ? '100px' : '0px'};
  overflow: hidden;
  transition: max-height 200ms;

`

const ToggleButton = styled('div')<{isOpen: boolean}>`
  cursor:pointer;
  font-weight: 500;
  text-align: center;
  padding: 3px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :hover {
  background-color: #F5F6FD;
  }

`

const Detail = styled.div`
  padding: 7px;
`
const Position = styled(Detail)`
  color: ${props => props.theme.colors.primary.default};
`

const Label = styled.span`
  color: #9AA6AC
`
type Props = {
  contact: TContactItem;
}
const ContactPerson = (props: Props) => {
  const { contact, ...rest } = props
  const [isExpanded, setIsExpanded] = React.useState(false)

  if (!contact) {
    return null
  }
  const toggleCollapse = () => setIsExpanded(!isExpanded)

  return (
    <Container {...rest}>
      <ToggleButton isOpen={isExpanded} onClick={toggleCollapse}>
        <ImgNameRight src="" name={contact.name} /> <span><ChevronDown /></span>
      </ToggleButton>
      <Content open={isExpanded}>
        <Position>{contact.position}</Position>
        <Detail><Label>Email:</Label> {contact.email}</Detail>
        <Detail><Label>Phone: </Label> {contact.phone}</Detail>
      </Content>
    </Container>
  )
}

export default ContactPerson
