import * as stateNames from 'constants/stateNames'
import * as ROUTES from 'constants/routes'
import { useCreate } from 'hooks'
import React from 'react'
import Layout from 'components/Layouts/Layout'
import {
  clinicCreateAction
} from '../../action/clinicActions'
import ClinicCreate from '../../components/Clinic/ClinicCreate'
import { clinicsCreateSerializer } from '../../serializers/clinicSerializer'

const ClinicCreateContainer = () => {
  const data = useCreate({
    stateName: stateNames.CLINICS_CREATE,
    action: clinicCreateAction,
    redirectUrl: ROUTES.CLINIC_LIST_PATH,
    serializer: clinicsCreateSerializer
  })

  return (
    <Layout>
      <ClinicCreate
        {...data}
      />
    </Layout>
  )
}
export default ClinicCreateContainer
