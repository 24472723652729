import * as stateNames from 'constants/stateNames'
import * as ROUTES from 'constants/routes'
import { ASSIGNMENT_ITEM } from 'constants/actionTypes'
import { WEEKS } from 'constants/backend'
import React from 'react'
import { find, map, path, pipe, propEq, propOr } from 'ramda'
import Layout from 'components/Layouts/Layout'
import { TAssignmentItem } from 'types'
import { useFetchItem, useUpdate } from 'hooks'
import { useDispatch } from 'react-redux'
import AssignmentUpdate from '../../components/Assignment/AssignmentUpdate'
import { assignmentItemFetch, assignmentUpdateAction } from '../../action/assignmentActions'
import { createSerializer } from '../../serializers/assignmentSerializer'

const AssignmentUpdateContainer = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    return () => {
      dispatch({ type: ASSIGNMENT_ITEM + '_CLEAR' })
    }
  }, [])
  const assignmentItem = useFetchItem<TAssignmentItem>({
    action: assignmentItemFetch,
    stateName: stateNames.ASSIGNMENT_ITEM
  })

  const update = useUpdate({
    action: assignmentUpdateAction,
    stateName: stateNames.ASSIGNMENT_UPDATE,
    redirectUrl: ROUTES.ASSIGNMENT_LIST_PATH,
    serializer: createSerializer
  })

  const data = path<any>(['data', 'data'], assignmentItem)
  const initialValues = {
    ...data,
    clinicIds: propOr([], 'clinics', data),
    workingDays: pipe(
      propOr([], 'workingDays'),
      map((item: any) => find(propEq('_id', item), WEEKS))
    )(data)
  }

  return (
    <Layout>
      <AssignmentUpdate
        {...update}
        item={assignmentItem}
        itemLoading={assignmentItem.loading}
        initialValues={initialValues}
      />
    </Layout>
  )
}

export default AssignmentUpdateContainer
