import * as ROUTES from 'constants/routes'
import React, { FunctionComponent } from 'react'
import { Field, FormRenderProps } from 'react-final-form'
import { FieldWrapper, DoubleField } from 'components/StyledElems'
import {
  InputField,
} from 'components/Form'
import CreateCancelButtons from 'components/UI/Buttons/CreateCancelButtons'
import { Merge, TData, TGetDataFromState, TPositionItem } from 'types'
import { normalizePhone } from 'utils/normalize'

type Props = Merge<FormRenderProps, {
  positionData: TGetDataFromState<TData<TPositionItem>>;
}>;

const ClinicCreateForm: FunctionComponent<Props> = props => {
  const { handleSubmit } = props

  return (
    <form onSubmit={handleSubmit}>
      <DoubleField>
        <div>
          <FieldWrapper>
            <Field
              component={InputField}
              name={'name'}
              label={'Название'}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Field
              autoComplete="off"
              name={'phoneNumber'}
              parse={normalizePhone}
              defaultValue={'+998'}
              component={InputField}
              label={'Телефон'}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Field
              component={InputField}
              name={'userLimit'}
              type={'number'}
              label={'Лимит пользователей'}
            />
          </FieldWrapper>
        </div>
        <div>
          <FieldWrapper>
            <Field
              component={InputField}
              name={'startTime'}
              type={'time'}
              label={'Начало рабочего дня'}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Field
              component={InputField}
              name={'endTime'}
              type={'time'}
              label={'Конец рабочего дня'}
            />
          </FieldWrapper>
        </div>
      </DoubleField>
      <CreateCancelButtons
        cancelPath={ROUTES.CLINIC_LIST_PATH}
        submitText="Сохранить"
      />
    </form>
  )
}

export default ClinicCreateForm
