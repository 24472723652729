/* eslint-disable max-len */
import React from 'react'
import SvgIcon from './SvgIcon'

export default props => {
  return (
    <SvgIcon width="64" height="64" viewBox="0 0 64 64" fill="none" {...props}>
      <path
        d="M48 10.6665V18.6665C48 20.081 47.4381 21.4375 46.4379 22.4377C45.4377 23.4379 44.0812 23.9998 42.6667 23.9998H21.3333C19.9188 23.9998 18.5623 23.4379 17.5621 22.4377C16.5619 21.4375 16 20.081 16 18.6665V10.6665C13.8783 10.6665 11.8434 11.5094 10.3431 13.0096C8.84285 14.5099 8 16.5448 8 18.6665V50.6665C8 52.7882 8.84285 54.8231 10.3431 56.3234C11.8434 57.8236 13.8783 58.6665 16 58.6665H48C50.1217 58.6665 52.1566 57.8236 53.6569 56.3234C55.1571 54.8231 56 52.7882 56 50.6665V18.6665C56 16.5448 55.1571 14.5099 53.6569 13.0096C52.1566 11.5094 50.1217 10.6665 48 10.6665Z"
        fill="#CACDD3"
      />
      <path
        d="M42.667 5.3335H21.3337C19.8609 5.3335 18.667 6.5274 18.667 8.00016V18.6668C18.667 20.1396 19.8609 21.3335 21.3337 21.3335H42.667C44.1397 21.3335 45.3337 20.1396 45.3337 18.6668V8.00016C45.3337 6.5274 44.1397 5.3335 42.667 5.3335Z"
        fill="#959CA7"
      />
    </SvgIcon>
  )
}
