import React, { FunctionComponent, AnchorHTMLAttributes } from 'react'
import { useHistory } from 'react-router-dom'
import { Merge } from 'types'
import { Button, SecondaryButton } from './index'

type Props = {
  to?: string;
  onClick?: () => void;
  disabled?: boolean;
}
const LinkButton: FunctionComponent<Props> = props => {
  const { to, ...rest } = props
  const history = useHistory()
  const onClick = ev => {
    ev.preventDefault()
    if (to) {
      return history.push(to)
    }
    return rest.onClick()
  }
  return <Button {...rest} onClick={onClick} />
}

export default LinkButton

export const LinkSecondaryButton: FunctionComponent<Merge<Props, AnchorHTMLAttributes<any>>> = props => {
  const { to, ...rest } = props
  const history = useHistory()

  const onClick = ev => {
    ev.preventDefault()

    if (to) {
      return history.push(to)
    }
    return rest.onClick(ev)
  }
  return <SecondaryButton {...rest} onClick={onClick} />
}
