import { getSerializedData } from 'utils/get'
import { filter, map, path, pipe, propOr } from 'ramda'
import { fields } from '../components/Assignment/AssignmentCreate'

const mapTimes = pipe(
  propOr([], 'workingTime'),
  map((item: any) => {
    return {
      startTime: item.startTime,
      endTime: item.endTime
    }
  }),
  filter((item: any) => item?.startTime && item.endTime)
)

export const createSerializer = params => {
  const serialized = getSerializedData(fields, params)
  return {
    ...serialized,
    status: path(['status', 'id'], params) || path(['status'], params),
    workingTime: mapTimes(params)
  }
}
