import React, { FunctionComponent, useState } from 'react'
import moment from 'moment'
import { path } from 'ramda'
import styled, { css } from 'styled-components'
import { DateRangePicker } from 'react-dates'
import { getFieldError } from 'utils/form'
import { InputLabel, InputError } from 'components/UI'
import { useField } from 'react-final-form'
import commonProps from './commonProps'
import { customArrowIcon } from './customIcons'

const Wrapper = styled.div`
  position: relative;
  z-index: 10;
`
const DateContainer = styled('div')<{isFocused?: boolean}>`
  align-items: center;
  background-color: ${props => props.theme.input.backgroundColor};
  border: 1px solid transparent;
  border-radius: ${props => props.theme.input.borderRadius};
  display:flex;
  height: 52px;
  transition: ${props => props.theme.cube.transition};
  ${props => props.isFocused && (
    css`
      background-color: white !important;
      border-color: ${props => props.theme.cube.primaryColor};
    `
  )}
  &:hover {
    background-color: ${props => props.theme.input.backgroundColorHover};
  }

  & .DateRangePicker {
    width: 100%;
  }
  & .DateRangePickerInput {
    background-color: transparent;
  }
  & .DateRangePickerInput_calendarIcon {
    outline: none;
    margin: 0 5px;
  }
  & .DateRangePickerInput_clearDates {
  
  }
  & .DateInput {
    background-color: transparent;
    min-width: 100px;
    width: calc(50% - 50px);
  }
  & .DateInput_input {
    background-color: transparent;
    border-bottom: none;
    color: ${props => props.theme.cube.textColor};
    font-size: 15px;
    text-align: center;
    &::placeholder {
      color: ${props => props.theme.input.placeholderColor};
    }
  }
`

type Props = {
  fromName: string;
  toName: string;
  label: string
}

const dateRangeProps = {
  ...commonProps,
  isOutsideRange: () => false,
  startDatePlaceholderText: 'Start Date',
  endDatePlaceholderText: 'End Date',
  customArrowIcon,
  showClearDates: true
}

const getFormattedDate = date => {
  const regEx = /\d{4}-{1}\d{2}-{1}\d{2}/

  if (regEx.test(date)) {
    return moment(date)
  }

  return null
}

const getValidDateFormat = momentDate => {
  const format = 'YYYY-MM-DD'
  if (moment(momentDate).isValid()) {
    return moment(momentDate).format(format)
  }
  return null
}

const DateDoubleField: FunctionComponent<Props> = props => {
  const [focusedInput, setFocused] = useState(null)
  const { label, fromName, toName, ...defaultProps } = props

  const from = useField(fromName)
  const to = useField(toName)

  const onChange = ({ startDate, endDate }) => {
    const validStartDate = getValidDateFormat(startDate)
    const validEndDate = getValidDateFormat(endDate)
    from.input.onChange(validStartDate)
    to.input.onChange(validEndDate)
  }

  const onFocusChange = (focused) => setFocused(focused)

  const startDate = path(['input', 'value'], from)
  const endDate = path(['input', 'value'], to)

  const isFocused = typeof focusedInput === 'string'
  const errorText = getFieldError(from.meta) + getFieldError(to.meta)

  return (
    <Wrapper>
      <InputLabel>{label}</InputLabel>
      <DateContainer
        isFocused={isFocused}
        onClick={event => {
          if (event.currentTarget === event.target) {
            onFocusChange('startDate')
          }
        }}
      >
        <DateRangePicker
          {...dateRangeProps}
          {...defaultProps}
          focusedInput={focusedInput}
          startDate={getFormattedDate(startDate)}
          endDate={getFormattedDate(endDate)}
          startDateId={fromName + '_start'}
          endDateId={toName + '_end'}
          onFocusChange={onFocusChange}
          onDatesChange={onChange}
        />
      </DateContainer>
      <InputError>{errorText}</InputError>
    </Wrapper>
  )
}

export default DateDoubleField
