import * as API from 'constants/api'
import axios from 'axios'
import { compose, curry, equals, isNil, path, prop } from 'ramda'
import { getCookie, setCookie } from './cookie'

// Request unauthorized status
const UNAUTHORIZED = 401

// GetSuccess
export const getPayloadFromSuccess = prop('data')

// GetError
export const getPayloadFromError = compose(data => !isNil(data) && Promise.reject(data), path(['response', 'data']))

// ErrorInterceptors
const errorInterceptors = curry((dispatch, error) => {
  const status = path(['response', 'status'], error)
  if (equals(UNAUTHORIZED, status) && dispatch) {
    setCookie('token', '')
  }
  return Promise.reject(error)
})
const axiosRequest = ({ getState, dispatch, getToken }, noCamelCase = false) => {
  const state = getState && getState()
  const token = path(['login', 'data', 'token'], state) || getCookie('token') || getToken
  axios.defaults.baseURL = API.ROOT_URL
  axios.defaults.timeout = 100000
  if (noCamelCase) {
  }

  if (token) {
    axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : ''
  } else {
    axios.defaults.headers.common = {}
  }
  axios.interceptors.response.use(response => response, errorInterceptors(dispatch))
  return axios
}

export default axiosRequest
