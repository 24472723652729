import Layout from 'components/Layouts/Layout'
import * as ROUTES from '../../constants/routes'
import {
  ClinicCreateContainer,
  ClinicListContainer,
  ClinicUpdateContainer
} from './containers/ClinicContainers'

export default () => [
  {
    exact: true,
    path: ROUTES.CLINIC_LIST_PATH,
    layout: Layout,
    component: ClinicListContainer
  },
  {
    exact: true,
    path: ROUTES.CLINIC_CREATE_PATH,
    component: ClinicCreateContainer
  },
  {
    exact: true,
    path: ROUTES.CLINIC_UPDATE_PATH,
    component: ClinicUpdateContainer
  }
]
