import { curry, isNil, map, path, pipe, prop, sum } from 'ramda'
import toNumber from 'utils/toNumber'
import { TGetDataFromState } from 'types'
import numberFormat from 'utils/numberFormat'

export const getDataFromState = curry((name, state): TGetDataFromState<any> => ({
  loading: path([name, 'loading'], state),
  failed: path([name, 'failed'], state),
  data: path([name, 'data'], state),
}))

export const getInCurrencyRate = (isPrimary, amount, rate) => isPrimary ? toNumber(amount) : toNumber(amount) * +rate
export const getInPrimaryRate = (isPrimary, amount, rate) => isPrimary ? toNumber(amount) : toNumber(amount) / +rate

export const sumBy = curry((key, list: any[]) => pipe(
  map(pipe(
    prop(key),
    toNumber)
  ),
  sum
)(list))

export const getFeeAmount = (amount, currency) => {
  if (isNil(amount)) {
    return 'N/A'
  }

  return numberFormat(amount, currency)
}
