import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import DetailTableRow from './DetailTableRow'

type Props = {
  link: string;
  align?: string;
  gutter?: number;
}
const DetailTableRowLinked = styled(DetailTableRow)`
  cursor: pointer;
  transition: background-color 200ms;
  :hover {
    background-color: #F5F6FD;  
  }
`
const DetailTableRowLink: FunctionComponent<Props> = props => {
  const { link, ...rest } = props

  const history = useHistory()
  const onClick = () => history.push(link)

  return <DetailTableRowLinked onClick={onClick} {...rest} />
}

export default DetailTableRowLink
