import * as API from 'constants/api'
import * as ACTION_TYPES from 'constants/actionTypes'
import axios, { getPayloadFromError, getPayloadFromSuccess } from 'utils/axios'
import { sprintf } from 'sprintf-js'

export const clinicCreateAction = params => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .post(API.CLINICS_CREATE, params)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.CLINICS_CREATE}`,
      payload
    })
  }
}

export const clinicListAction = (params) => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.CLINICS_LIST, { params })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.CLINICS_LIST}`,
      payload
    })
  }
}

export const clinicUpdateAction = (id, params) => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .put(sprintf(API.CLINICS_UPDATE, id), params)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.CLINICS_CREATE}`,
      payload
    })
  }
}
export const clinicDeleteAction = id => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .delete(sprintf(API.CLINICS_DELETE, id))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.CLINICS_DELETE}`,
      payload
    })
  }
}

export const clinicItemFetch = id => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(sprintf(API.CLINICS_ITEM, id))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: `${ACTION_TYPES.CLINICS_ITEM}`,
      payload
    })
  }
}
