import { curry, find, path, pathOr, propEq } from 'ramda'
import { sprintf } from 'sprintf-js'

import axios, { getPayloadFromSuccess } from './axios'

export const getOptions = (store, { api, search, params }) => {
  const defaultParams = { search, ...params }
  return axios(store)
    .get(api, { params: { size: 50, ...defaultParams } })
    .then(pathOr([], ['data', 'data']))
}

export const getOption = (store, { api, apiDetail }) => id => {
  return axios(store)
    .get(apiDetail ? sprintf(apiDetail, id) : `${api}${id}/`)
    .then(getPayloadFromSuccess)
}

export const createOption = (store, { api, value }) => {
  return axios(store)
    .post(`${api}`, { name: value })
    .then(getPayloadFromSuccess)
}

export const getStaticOptions = (search, list) => Promise.resolve(list)

export const getStaticOption = (id, list) => Promise.resolve(find(propEq(id, 'id'))(list))

export const defaultGetText = curry((text, obj) => path(text, obj))

export const defaultGetValue = curry((value, obj) => path(value, obj))
