import { DefaultTheme } from 'styled-components'

export const cubeTheme = {
  borderRadius: {
    md: '10px',
    sm: '5px'
  },
  shadows: {
    md: '0px 4px 15px rgba(211, 216, 224, 0.5);',
    sm: '',
    lg: '',
  },
  palette: {

    secondary: {
      main: '',
      light: '',
      dark: '',
      hover: '',
      active: '',
      focus: ''

    },
    danger: {
      main: '',
      light: '',
      dark: '',
      hover: '',
      active: '',
      focus: ''

    }
  },
  input: {
    colors: {

    }
  },
}

const theme: DefaultTheme = {
  cube: cubeTheme,
  border: '1px solid #eaebee',
  borderColor: '#eaebee',
  borderRadius: '8px',
  colors: {
    lightGreyColor: '#9AA6AC',
    text: '#36434E',
    primary: {
      default: '#6770E6'
    },
    secondary: {
      default: '#e3e7ea'
    }
  },
}

export default theme
