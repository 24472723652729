import * as ROUTES from '../constants/routes'

export const MENU_KEYS = {
  ORDER: 'order',
  ASSIGNMENT: 'assignment',
  CLINIC: 'clinic',
  BILLING: 'preinvoice',
  CONTRACT: 'contract',
  CATALOG: 'catalog',
  REVIEW: 'review',
  TRANSACTIONS: 'transaction',
  TRANSACTIONS_PENDING: 'transactions-pending',
  PAYMENTS: 'payments',
  OPERATIONS: 'operations',
  MANUFACTURING: 'manufacturing',
  REPORTS: 'reports',
  SETTINGS: 'tags',
  STATISTICS: 'statistics',
  BANK_ACCOUNT: 'bankAccount',
  CLIENT: 'client',
  STAFF: 'staff',
  FINANCE: 'transaction',
  CASHBOX: 'cashbox',
  OUTSOURCE: 'outsource',
  TIME_SHEET: 'timesheet',
  EXPENSE: 'expense',
  APPROVAL: 'assignment',
}

export default [
  {
    key: MENU_KEYS.ASSIGNMENT,
    title: 'Пользователи',
    url: ROUTES.ASSIGNMENT_LIST_PATH,
    perms: MENU_KEYS.ASSIGNMENT,
    children: [
      {
        key: MENU_KEYS.ASSIGNMENT,
        title: 'Пользователи',
        url: ROUTES.ASSIGNMENT_LIST_PATH,
      }
    ]
  },
  {
    key: MENU_KEYS.CLINIC,
    title: 'Клиники',
    url: ROUTES.CLINIC_LIST_PATH,
    perms: MENU_KEYS.CLINIC,
    children: [
      {
        key: MENU_KEYS.CLINIC,
        title: 'Клиники',
        url: ROUTES.CLINIC_LIST_PATH,
      }
    ]
  },
]
