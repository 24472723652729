import * as ROUTES from 'constants/routes'
import SignIn from './sign-in'
import order from './assignment'
import clinic from './clinic'
import Main from './Main'

const Routes = store => [
  {
    exact: true,
    path: ROUTES.LOGIN_URL,
    component: SignIn
  },
  {
    exact: true,
    path: '/',
    component: Main
  },
  ...order(store),
  ...clinic(store),
]

export default Routes
