import { equals as equal, path } from 'ramda'
import { useHistory, useParams } from 'react-router-dom'

import { getDataFromState } from 'utils/get'
import { mapResponseToFormError } from 'utils/form'

import { useTypedSelector, usePromiseDispatch } from 'etc/reducers'
import toSnakeCase from '../utils/toSnakeCase'
import { TGetDataFromState, TUseUpdateParams, TUseUpdate } from '../types'

const useUpdate = <T extends any>(params: TUseUpdateParams): TUseUpdate => {
  const {
    stateName,
    action,
    redirectUrl,
    initialValues,
    key = 'id',
    onSuccess,
    serializer = toSnakeCase,
    reject = true
  } = params

  const paramsRoute = useParams()
  const history = useHistory()
  const dispatch = usePromiseDispatch()
  const state = useTypedSelector<TGetDataFromState<T>>(state => getDataFromState(stateName, state), equal)
  const id: string = path([key], paramsRoute)

  const onSubmit = (values: Record<string, any>) => {
    const serializeValues = serializer(values)

    return dispatch(action(id, serializeValues))
      .then(data => {
        if (onSuccess) {
          onSuccess(data, { values })
        } else if (redirectUrl) {
          history.push(redirectUrl)
        }
      })
      .catch((eee) => {
        if (reject) {
          return mapResponseToFormError(eee)
        }

        return Promise.reject(eee)
      })
  }

  return { ...state, id, onSubmit, initialValues, isUpdate: true }
}

export default useUpdate
